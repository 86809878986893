const mainData = {
  2021: {
    argentina: {
      population: true,
      prevalence: null,
      riskPopulation: null,
      vectorialRist: null,
      peopleTestedOne: 632958,
      peopleTestedTwo: 12453,
      peopleTested: 632958,
      confirmedCases: 12453,
      confirmedOne: 2,
      confirmedTwo: 100,
      confirmedThree: null,
      percentConfirmedCases: 2.0,
      inTreatment: null,
      percentInTreatment: null,
      acuteCases: 54,
      cronicOne: `97%`,
      cronicTwo: null,
      cronicCases: 12409,
      oralTransmission: 0,
      vectorTransmission: 0,
      verticalTransmission: 0,
      othersTransmission: 0,
      testedPregnant: 235536,
      confirmedPregnant: 3506,
      pregnantOne: 37,
      pregnantTwo: 100,
      pregnantThree: `1,5%`,
      percentConfirmedPregnant: 1.5,
      pregnantInTreatment: 73,
      percentPregnantInTreatment: 2,
      youngTested: 15167,
      youngConfirmed: 266,
      youngOne: 2,
      youngTwo: 100,
      youngThree: `266 (1,7%)`,
      percentYoungConfirmed: 1.8,
      youngInTreatment: null,
      percentYoungInTreatment: null,
      tested: [1027, 14140, 367927, 249864],
      genres: [434235, 198512, 211],
    },
    brasil: {
      population: true,
      prevalence: true,
      riskPopulation: null,
      vectorialRist: null,
      peopleTestedOne: 46725,
      peopleTestedTwo: 328,
      peopleTested: 46725,
      confirmedCases: 328,
      confirmedOne: `328 (0,7%)`,
      confirmedTwo: null,
      confirmedThree: null,
      percentConfirmedCases: 0.7,
      inTreatment: 296,
      percentInTreatment: 90,
      acuteCases: 328,
      cronicOne: `100%`,
      cronicTwo: null,
      cronicCases: 0,
      oralTransmission: 287,
      vectorTransmission: 26,
      verticalTransmission: 1,
      othersTransmission: 14,
      testedPregnant: 623,
      confirmedPregnant: 4,
      pregnantOne: `1,3%`,
      pregnantTwo: null,
      pregnantThree: `4 (0,64%)`,
      percentConfirmedPregnant: 0.6,
      pregnantInTreatment: 4,
      percentPregnantInTreatment: 100.0,
      youngTested: 4661,
      youngConfirmed: 73,
      youngOne: 2,
      youngTwo: 100,
      youngThree: `91%`,
      percentYoungConfirmed: 1.6,
      youngInTreatment: 67,
      percentYoungInTreatment: 91,
      tested: [602, 4059, 22246, 19818],
      genres: [23830, 22783, 112],
    },
    colombia: {
      population: true,
      prevalence: true,
      riskPopulation: true,
      vectorialRist: true,
      peopleTestedOne: null,
      peopleTestedTwo: null,
      peopleTested: null,
      confirmedCases: null,
      confirmedOne: null,
      confirmedTwo: null,
      confirmedThree: null,
      percentConfirmedCases: null,
      inTreatment: null,
      percentInTreatment: null,
      acuteCases: null,
      cronicOne: null,
      cronicTwo: null,
      cronicCases: null,
      oralTransmission: 0,
      vectorTransmission: 0,
      verticalTransmission: 0,
      othersTransmission: 0,
      testedPregnant: null,
      confirmedPregnant: null,
      pregnantOne: null,
      pregnantTwo: null,
      pregnantThree: null,
      percentConfirmedPregnant: null,
      pregnantInTreatment: null,
      percentPregnantInTreatment: null,
      youngTested: null,
      youngConfirmed: null,
      youngOne: null,
      youngTwo: null,
      youngThree: null,
      percentYoungConfirmed: null,
      youngInTreatment: null,
      percentYoungInTreatment: null,
      tested: [],
      genres: [],
    },
    guatemala: {
      population: true,
      prevalence: null,
      riskPopulation: null,
      vectorialRist: null,
      peopleTestedOne: 960,
      peopleTestedTwo: 314,
      peopleTested: 960,
      confirmedCases: 314,
      confirmedOne: 33,
      confirmedTwo: 100,
      confirmedThree: null,
      percentConfirmedCases: 32.7,
      inTreatment: 243,
      percentInTreatment: 77,
      acuteCases: null,
      cronicOne: null,
      cronicTwo: null,
      cronicCases: null,
      oralTransmission: 0,
      vectorTransmission: 0,
      verticalTransmission: 0,
      othersTransmission: 0,
      testedPregnant: 175,
      confirmedPregnant: 3,
      pregnantOne: 18,
      pregnantTwo: 100,
      pregnantThree: `3 (1,7%)`,
      percentConfirmedPregnant: 1.7,
      pregnantInTreatment: null,
      percentPregnantInTreatment: null,
      youngTested: 334,
      youngConfirmed: 174,
      youngOne: 35,
      youngTwo: 100,
      youngThree: `174 (55%)`,
      percentYoungConfirmed: null,
      youngInTreatment: null,
      percentYoungInTreatment: null,
      tested: [271, 63, 482, 144],
      genres: [505, 455, 0],
    },
    paraguai: {
      population: true,
      prevalence: null,
      riskPopulation: null,
      vectorialRist: null,
      peopleTestedOne: 16001,
      peopleTestedTwo: 180,
      peopleTested: 16001,
      confirmedCases: 180,
      confirmedOne: 1,
      confirmedTwo: 100,
      confirmedThree: null,
      percentConfirmedCases: 1.1,
      inTreatment: 100,
      percentInTreatment: 55,
      acuteCases: null,
      cronicOne: null,
      cronicTwo: null,
      cronicCases: null,
      oralTransmission: 0,
      vectorTransmission: 0,
      verticalTransmission: 0,
      othersTransmission: 0,
      testedPregnant: 11601,
      confirmedPregnant: 166,
      pregnantOne: 72,
      pregnantTwo: 100,
      pregnantThree: `166 (1,4%)`,
      percentConfirmedPregnant: 1.2,
      pregnantInTreatment: null,
      percentPregnantInTreatment: null,
      youngTested: 252,
      youngConfirmed: 14,
      youngOne: `252 (1,6%)`,
      youngTwo: null,
      youngThree: 14,
      percentYoungConfirmed: 5.6,
      youngInTreatment: null,
      percentYoungInTreatment: null,
      tested: [],
      genres: [],
    },
    reinounido: {
      population: true,
      prevalence: null,
      riskPopulation: true,
      vectorialRist: null,
      peopleTestedOne: null,
      peopleTestedTwo: null,
      peopleTested: null,
      confirmedCases: null,
      confirmedOne: null,
      confirmedTwo: null,
      confirmedThree: null,
      percentConfirmedCases: null,
      inTreatment: null,
      percentInTreatment: null,
      acuteCases: null,
      cronicOne: null,
      cronicTwo: null,
      cronicCases: null,
      oralTransmission: 0,
      vectorTransmission: 0,
      verticalTransmission: 0,
      othersTransmission: 0,
      testedPregnant: null,
      confirmedPregnant: null,
      pregnantOne: null,
      pregnantTwo: null,
      pregnantThree: null,
      percentConfirmedPregnant: null,
      pregnantInTreatment: null,
      percentPregnantInTreatment: null,
      youngTested: null,
      youngConfirmed: null,
      youngOne: null,
      youngTwo: null,
      youngThree: null,
      percentYoungConfirmed: null,
      youngInTreatment: null,
      percentYoungInTreatment: null,
      tested: [],
      genres: [],
    },
    suica: {
      population: true,
      prevalence: null,
      riskPopulation: null,
      vectorialRist: null,
      peopleTestedOne: null,
      peopleTestedTwo: null,
      peopleTested: null,
      confirmedCases: null,
      confirmedOne: null,
      confirmedTwo: null,
      confirmedThree: null,
      percentConfirmedCases: null,
      inTreatment: null,
      percentInTreatment: null,
      acuteCases: null,
      cronicOne: null,
      cronicTwo: null,
      cronicCases: null,
      oralTransmission: 0,
      vectorTransmission: 0,
      verticalTransmission: 0,
      othersTransmission: 0,
      testedPregnant: null,
      confirmedPregnant: null,
      pregnantOne: null,
      pregnantTwo: null,
      pregnantThree: null,
      percentConfirmedPregnant: null,
      pregnantInTreatment: null,
      percentPregnantInTreatment: null,
      youngTested: null,
      youngConfirmed: null,
      youngOne: null,
      youngTwo: null,
      youngThree: null,
      percentYoungConfirmed: null,
      youngInTreatment: null,
      percentYoungInTreatment: null,
      tested: [],
      genres: [],
    },
    overall: {
      population: null,
      prevalence: null,
      riskPopulation: null,
      vectorialRist: null,
      peopleTestedOne: null,
      peopleTestedTwo: null,
      peopleTested: 696644,
      confirmedCases: 13275,
      confirmedOne: null,
      confirmedTwo: null,
      confirmedThree: null,
      percentConfirmedCases: 1.9,
      inTreatment: 639,
      percentInTreatment: 4.8,
      acuteCases: 382,
      cronicOne: null,
      cronicTwo: null,
      cronicCases: 12409,
      oralTransmission: 287,
      vectorTransmission: 26,
      verticalTransmission: 1,
      othersTransmission: 0,
      testedPregnant: 247935,
      confirmedPregnant: 3650,
      pregnantOne: null,
      pregnantTwo: null,
      pregnantThree: null,
      percentConfirmedPregnant: 1.5,
      pregnantInTreatment: 77,
      percentPregnantInTreatment: 2.0,
      youngTested: 20080,
      youngConfirmed: 353,
      youngOne: null,
      youngTwo: null,
      youngThree: null,
      percentYoungConfirmed: 1.8,
      youngInTreatment: 67,
      percentYoungInTreatment: 19.0,
      tested: [],
      genres: [],
    },
  },
  2022: {
    argentina: {
      population: true,
      prevalence: null,
      riskPopulation: null,
      vectorialRist: null,
      peopleTestedOne: 719678,
      peopleTestedTwo: 15366,
      peopleTested: 719678,
      confirmedCases: 15366,
      confirmedOne: 2,
      confirmedTwo: 100,
      confirmedThree: null,
      percentConfirmedCases: 2.1,
      inTreatment: null,
      percentInTreatment: null,
      acuteCases: 58,
      cronicOne: 7,
      cronicTwo: 10,
      cronicCases: 9928,
      oralTransmission: 0,
      vectorTransmission: 0,
      verticalTransmission: 0,
      othersTransmission: 0,
      testedPregnant: 244177,
      confirmedPregnant: 3585,
      pregnantOne: 34,
      pregnantTwo: 100,
      pregnantThree: `3.600 (1,5%)`,
      percentConfirmedPregnant: 1.5,
      pregnantInTreatment: null,
      percentPregnantInTreatment: null,
      youngTested: 26943,
      youngConfirmed: 356,
      youngOne: 4,
      youngTwo: 100,
      youngThree: `1,3%`,
      percentYoungConfirmed: 1.3,
      youngInTreatment: null,
      percentYoungInTreatment: null,
      tested: [1475, 25468, 338474, 58304, 295957],
      genres: [361319, 358360, 0],
    },
    brasil: {
      population: true,
      prevalence: true,
      riskPopulation: null,
      vectorialRist: null,
      peopleTestedOne: 54683,
      peopleTestedTwo: 414,
      peopleTested: 54683,
      confirmedCases: 414,
      confirmedOne: `54.683`,
      confirmedTwo: `414`,
      confirmedThree: `0.08%`,
      percentConfirmedCases: 0.8,
      inTreatment: 367,
      percentInTreatment: 88,
      acuteCases: 414,
      cronicOne: `100%`,
      cronicTwo: null,
      cronicCases: 0,
      oralTransmission: 339,
      vectorTransmission: 19,
      verticalTransmission: 3,
      othersTransmission: 53,
      testedPregnant: 3805,
      confirmedPregnant: 2,
      pregnantOne: 7,
      pregnantTwo: 100,
      pregnantThree: `2 (0,01%)`,
      percentConfirmedPregnant: 0.1,
      pregnantInTreatment: 2,
      percentPregnantInTreatment: 100.0,
      youngTested: 3397,
      youngConfirmed: 90,
      youngOne: 6,
      youngTwo: 100,
      youngThree: 100,
      percentYoungConfirmed: 2.6,
      youngInTreatment: 85,
      percentYoungInTreatment: 94.4,
      tested: [347, 3050, 31302, 19984],
      genres: [32671, 22001, 11],
    },
    colombia: {
      population: true,
      prevalence: true,
      riskPopulation: true,
      vectorialRist: true,
      peopleTestedOne: 1077638,
      peopleTestedTwo: 1185,
      peopleTested: 1077638,
      confirmedCases: 1185,
      confirmedOne: `1.185 (0,12%)`,
      confirmedTwo: `1.077.638`,
      confirmedThree: null,
      percentConfirmedCases: 0.1,
      inTreatment: 323,
      percentInTreatment: 27,
      acuteCases: 15,
      cronicOne: `99%`,
      cronicTwo: null,
      cronicCases: 1169,
      oralTransmission: 9,
      vectorTransmission: 1175,
      verticalTransmission: 1,
      othersTransmission: 0,
      testedPregnant: 74745,
      confirmedPregnant: 174,
      pregnantOne: 7,
      pregnantTwo: 100,
      pregnantThree: `174 (0,23%)`,
      percentConfirmedPregnant: 0.2,
      pregnantInTreatment: 17,
      percentPregnantInTreatment: 10,
      youngTested: 21395,
      youngConfirmed: 32,
      youngOne: 2,
      youngTwo: 100,
      youngThree: `32 (0,15%)`,
      percentYoungConfirmed: 0.1,
      youngInTreatment: 20,
      percentYoungInTreatment: 62,
      tested: [4149, 13866, 3380, 16026],
      genres: [],
    },
    guatemala: {
      population: true,
      prevalence: null,
      riskPopulation: null,
      vectorialRist: null,
      peopleTestedOne: 2153,
      peopleTestedTwo: 380,
      peopleTested: 2153,
      confirmedCases: 380,
      confirmedOne: 18,
      confirmedTwo: 100,
      confirmedThree: null,
      percentConfirmedCases: 17.6,
      inTreatment: 480,
      percentInTreatment: 126,
      acuteCases: 1,
      cronicOne: `99,7%`,
      cronicTwo: null,
      cronicCases: 379,
      oralTransmission: 0,
      vectorTransmission: 0,
      verticalTransmission: 0,
      othersTransmission: 0,
      testedPregnant: 199,
      confirmedPregnant: 2,
      pregnantOne: 9,
      pregnantTwo: 100,
      pregnantThree: 100,
      percentConfirmedPregnant: 1.0,
      pregnantInTreatment: 0,
      percentPregnantInTreatment: 0.0,
      youngTested: 515,
      youngConfirmed: 34,
      youngOne: 24,
      youngTwo: 100,
      youngThree: 100,
      percentYoungConfirmed: 6.6,
      youngInTreatment: 17,
      percentYoungInTreatment: 50.0,
      tested: [316, 199, 1257, 381],
      genres: [1312, 841, 0],
    },
    paraguai: {
      population: true,
      prevalence: null,
      riskPopulation: null,
      vectorialRist: null,
      peopleTestedOne: 24442,
      peopleTestedTwo: 323,
      peopleTested: 24442,
      confirmedCases: 323,
      confirmedOne: 1,
      confirmedTwo: 100,
      confirmedThree: null,
      percentConfirmedCases: 1.3,
      inTreatment: 111,
      percentInTreatment: 34,
      acuteCases: null,
      cronicOne: null,
      cronicTwo: null,
      cronicCases: null,
      oralTransmission: 0,
      vectorTransmission: 0,
      verticalTransmission: 0,
      othersTransmission: 0,
      testedPregnant: 9409,
      confirmedPregnant: 112,
      pregnantOne: 38,
      pregnantTwo: 100,
      pregnantThree: `112 (1,2%)`,
      percentConfirmedPregnant: 1.2,
      pregnantInTreatment: null,
      percentPregnantInTreatment: null,
      youngTested: 5269,
      youngConfirmed: 20,
      youngOne: 21,
      youngTwo: 100,
      youngThree: 20,
      percentYoungConfirmed: 0.4,
      youngInTreatment: null,
      percentYoungInTreatment: null,
      tested: [],
      genres: [],
    },
    reinounido: {
      population: true,
      prevalence: null,
      riskPopulation: true,
      vectorialRist: null,
      peopleTestedOne: 267,
      peopleTestedTwo: 51,
      peopleTested: 267,
      confirmedCases: 51,
      confirmedOne: 19,
      confirmedTwo: 100,
      confirmedThree: null,
      percentConfirmedCases: 19.1,
      inTreatment: 28,
      percentInTreatment: 55,
      acuteCases: 0,
      cronicOne: `100% (51)`,
      cronicTwo: null,
      cronicCases: 51,
      oralTransmission: 0,
      vectorTransmission: 49,
      verticalTransmission: 2,
      othersTransmission: 0,
      testedPregnant: 80,
      confirmedPregnant: `0`,
      pregnantOne: 80,
      pregnantTwo: `0`,
      pregnantThree: null,
      percentConfirmedPregnant: 0.0,
      pregnantInTreatment: 0,
      percentPregnantInTreatment: null,
      youngTested: 12,
      youngConfirmed: `0`,
      youngOne: 4,
      youngTwo: 100,
      youngThree: null,
      percentYoungConfirmed: 0.0,
      youngInTreatment: 0,
      percentYoungInTreatment: null,
      tested: [2, 10, 203, 52],
      genres: [202, 65, 0],
    },
    suica: {
      population: true,
      prevalence: null,
      riskPopulation: null,
      vectorialRist: null,
      peopleTestedOne: 439,
      peopleTestedTwo: 20,
      peopleTested: 439,
      confirmedCases: 20,
      confirmedOne: 4,
      confirmedTwo: 10,
      confirmedThree: null,
      percentConfirmedCases: 4.6,
      inTreatment: 1,
      percentInTreatment: 5.0,
      acuteCases: 0,
      cronicOne: `100% (20)`,
      cronicTwo: null,
      cronicCases: 20,
      oralTransmission: 0,
      vectorTransmission: 0,
      verticalTransmission: 0,
      othersTransmission: 0,
      testedPregnant: 56,
      confirmedPregnant: 1,
      pregnantOne: 13,
      pregnantTwo: 100,
      pregnantThree: 1,
      percentConfirmedPregnant: 1.8,
      pregnantInTreatment: 0,
      percentPregnantInTreatment: 0.0,
      youngTested: 28,
      youngConfirmed: 1,
      youngOne: 6,
      youngTwo: 100,
      youngThree: `1 (3,6%)`,
      percentYoungConfirmed: 3.6,
      youngInTreatment: 0,
      percentYoungInTreatment: 0.0,
      tested: [1, 27, 272, 139],
      genres: [332, 107, 0],
    },
    overall: {
      population: null,
      prevalence: null,
      riskPopulation: null,
      vectorialRist: null,
      peopleTestedOne: 1879300,
      peopleTestedTwo: 17739,
      peopleTested: 1879300,
      confirmedCases: 17739,
      confirmedOne: null,
      confirmedTwo: null,
      confirmedThree: null,
      percentConfirmedCases: 0.9,
      inTreatment: 1310,
      percentInTreatment: 7.4,
      acuteCases: 488,
      cronicOne: null,
      cronicTwo: null,
      cronicCases: 11547,
      oralTransmission: 348,
      vectorTransmission: 1243,
      verticalTransmission: 6,
      othersTransmission: 0,
      testedPregnant: 332471,
      confirmedPregnant: 3876,
      pregnantOne: null,
      pregnantTwo: null,
      pregnantThree: null,
      percentConfirmedPregnant: 1.2,
      pregnantInTreatment: 19,
      percentPregnantInTreatment: 0.0,
      youngTested: 57559,
      youngConfirmed: 533,
      youngOne: null,
      youngTwo: null,
      youngThree: null,
      percentYoungConfirmed: 0.9,
      youngInTreatment: 122,
      percentYoungInTreatment: 22.9,
      tested: [],
      genres: [],
    },
  },
  2023: {
    argentina: {
      population: true,
      prevalence: null,
      riskPopulation: null,
      vectorialRist: null,
      peopleTestedOne: `631.023`,
      peopleTestedTwo: `12.889`,
      peopleTested: 631023,
      confirmedCases: 12889,
      confirmedOne: `2`,
      confirmedTwo: `100`,
      confirmedThree: null,
      percentConfirmedCases: 2.0,
      inTreatment: null,
      percentInTreatment: null,
      acuteCases: 58,
      cronicOne: `99%`,
      cronicTwo: null,
      cronicCases: 9242,
      oralTransmission: 0,
      vectorTransmission: 0,
      verticalTransmission: 0,
      othersTransmission: 0,
      testedPregnant: 215704,
      confirmedPregnant: 2457,
      pregnantOne: `34`,
      pregnantTwo: `100`,
      pregnantThree: `2.500 (1,1%)`,
      percentConfirmedPregnant: 1.1,
      pregnantInTreatment: null,
      percentPregnantInTreatment: null,
      youngTested: 23914,
      youngConfirmed: 283,
      youngOne: `4`,
      youngTwo: `100`,
      youngThree: `1,2%`,
      percentYoungConfirmed: 1.2,
      youngInTreatment: null,
      percentYoungInTreatment: null,
      tested: [1716, 22198, 309008, 53171, 244930],
      genres: [316868, 314155, 0],
    },
    brasil: {
      population: true,
      prevalence: true,
      riskPopulation: null,
      vectorialRist: null,
      peopleTestedOne: 95284,
      peopleTestedTwo: 658,
      peopleTested: 95284,
      confirmedCases: 658,
      confirmedOne: `95.284`,
      confirmedTwo: `658`,
      confirmedThree: `0.76%`,
      percentConfirmedCases: 0.7,
      inTreatment: 817,
      percentInTreatment: 124,
      acuteCases: 658,
      cronicOne: 10,
      cronicTwo: 10,
      cronicCases: 0,
      oralTransmission: 482,
      vectorTransmission: 75,
      verticalTransmission: 3,
      othersTransmission: 98,
      testedPregnant: 1342,
      confirmedPregnant: 14,
      pregnantOne: `1`,
      pregnantTwo: `100`,
      pregnantThree: `100`,
      percentConfirmedPregnant: 1.0,
      pregnantInTreatment: `36%`,
      percentPregnantInTreatment: `5`,
      youngTested: 9154,
      youngConfirmed: 131,
      youngOne: `10`,
      youngTwo: `100`,
      youngThree: `100`,
      percentYoungConfirmed: 1.4,
      youngInTreatment: `105 (80,2%)`,
      percentYoungInTreatment: 80,
      tested: [764, 8390, 42950, 43180],
      genres: [53192, 42072, 20],
    },
    colombia: {
      population: true,
      prevalence: true,
      riskPopulation: true,
      vectorialRist: true,
      peopleTestedOne: null,
      peopleTestedTwo: null,
      peopleTested: null,
      confirmedCases: null,
      confirmedOne: null,
      confirmedTwo: null,
      confirmedThree: null,
      percentConfirmedCases: null,
      inTreatment: null,
      percentInTreatment: null,
      acuteCases: null,
      cronicOne: null,
      cronicTwo: null,
      cronicCases: null,
      oralTransmission: 0,
      vectorTransmission: 0,
      verticalTransmission: 0,
      othersTransmission: 0,
      testedPregnant: null,
      confirmedPregnant: null,
      pregnantOne: null,
      pregnantTwo: null,
      pregnantThree: null,
      percentConfirmedPregnant: null,
      pregnantInTreatment: null,
      percentPregnantInTreatment: null,
      youngTested: null,
      youngConfirmed: null,
      youngOne: null,
      youngTwo: null,
      youngThree: null,
      percentYoungConfirmed: null,
      youngInTreatment: null,
      percentYoungInTreatment: null,
      tested: [],
      genres: [],
    },
    guatemala: {
      population: true,
      prevalence: null,
      riskPopulation: null,
      vectorialRist: null,
      peopleTestedOne: 3119,
      peopleTestedTwo: 685,
      peopleTested: 3119,
      confirmedCases: 685,
      confirmedOne: 30,
      confirmedTwo: 100,
      confirmedThree: null,
      percentConfirmedCases: 22,
      inTreatment: 159,
      percentInTreatment: 23.2,
      acuteCases: null,
      cronicOne: null,
      cronicTwo: null,
      cronicCases: null,
      oralTransmission: 0,
      vectorTransmission: 0,
      verticalTransmission: 0,
      othersTransmission: 0,
      testedPregnant: 233,
      confirmedPregnant: 13,
      pregnantOne: 7,
      pregnantTwo: 100,
      pregnantThree: `13 (4%)`,
      percentConfirmedPregnant: 5.6,
      pregnantInTreatment: null,
      percentPregnantInTreatment: null,
      youngTested: 597,
      youngConfirmed: 28,
      youngOne: 17,
      youngTwo: 100,
      youngThree: `28 (4,69%)`,
      percentYoungConfirmed: 4.7,
      youngInTreatment: null,
      percentYoungInTreatment: null,
      tested: [43, 554, 1869, 653, 387],
      genres: [2300, 819, 0],
    },
    paraguai: {
      population: true,
      prevalence: null,
      riskPopulation: null,
      vectorialRist: null,
      peopleTestedOne: 18573,
      peopleTestedTwo: 308,
      peopleTested: 18573,
      confirmedCases: 308,
      confirmedOne: 2,
      confirmedTwo: 10,
      confirmedThree: null,
      percentConfirmedCases: 1.7,
      inTreatment: 119,
      percentInTreatment: 38,
      acuteCases: null,
      cronicOne: null,
      cronicTwo: null,
      cronicCases: null,
      oralTransmission: 0,
      vectorTransmission: 0,
      verticalTransmission: 0,
      othersTransmission: 0,
      testedPregnant: 13433,
      confirmedPregnant: 181,
      pregnantOne: 7,
      pregnantTwo: 10,
      pregnantThree: `181 (1,3%)`,
      percentConfirmedPregnant: 1.3,
      pregnantInTreatment: null,
      percentPregnantInTreatment: null,
      youngTested: 1773,
      youngConfirmed: 72,
      youngOne: 1,
      youngTwo: 10,
      youngThree: 100,
      percentYoungConfirmed: 4.0,
      youngInTreatment: null,
      percentYoungInTreatment: null,
      tested: [],
      genres: [],
    },
    reinounido: {
      population: true,
      prevalence: null,
      riskPopulation: true,
      vectorialRist: null,
      peopleTestedOne: null,
      peopleTestedTwo: null,
      peopleTested: null,
      confirmedCases: null,
      confirmedOne: null,
      confirmedTwo: null,
      confirmedThree: null,
      percentConfirmedCases: null,
      inTreatment: null,
      percentInTreatment: null,
      acuteCases: null,
      cronicOne: null,
      cronicTwo: null,
      cronicCases: null,
      oralTransmission: 0,
      vectorTransmission: 0,
      verticalTransmission: 0,
      othersTransmission: 0,
      testedPregnant: null,
      confirmedPregnant: null,
      pregnantOne: null,
      pregnantTwo: null,
      pregnantThree: null,
      percentConfirmedPregnant: null,
      pregnantInTreatment: null,
      percentPregnantInTreatment: null,
      youngTested: null,
      youngConfirmed: null,
      youngOne: null,
      youngTwo: null,
      youngThree: null,
      percentYoungConfirmed: null,
      youngInTreatment: null,
      percentYoungInTreatment: null,
      tested: [],
      genres: [],
    },
    suica: {
      population: true,
      prevalence: null,
      riskPopulation: null,
      vectorialRist: null,
      peopleTestedOne: null,
      peopleTestedTwo: null,
      peopleTested: null,
      confirmedCases: null,
      confirmedOne: null,
      confirmedTwo: null,
      confirmedThree: null,
      percentConfirmedCases: null,
      inTreatment: null,
      percentInTreatment: null,
      acuteCases: null,
      cronicOne: null,
      cronicTwo: null,
      cronicCases: null,
      oralTransmission: 0,
      vectorTransmission: 0,
      verticalTransmission: 0,
      othersTransmission: 0,
      testedPregnant: null,
      confirmedPregnant: null,
      pregnantOne: null,
      pregnantTwo: null,
      pregnantThree: null,
      percentConfirmedPregnant: null,
      pregnantInTreatment: null,
      percentPregnantInTreatment: null,
      youngTested: null,
      youngConfirmed: null,
      youngOne: null,
      youngTwo: null,
      youngThree: null,
      percentYoungConfirmed: null,
      youngInTreatment: null,
      percentYoungInTreatment: null,
      tested: [],
      genres: [],
    },
    overall: {
      population: null,
      prevalence: null,
      riskPopulation: null,
      vectorialRist: null,
      peopleTestedOne: 744880,
      peopleTestedTwo: 13855,
      peopleTested: 744880,
      confirmedCases: 13855,
      confirmedOne: null,
      confirmedTwo: null,
      confirmedThree: null,
      percentConfirmedCases: 1.9,
      inTreatment: 936,
      percentInTreatment: 6.8,
      acuteCases: 716,
      cronicOne: null,
      cronicTwo: null,
      cronicCases: 9242,
      oralTransmission: 482,
      vectorTransmission: 75,
      verticalTransmission: 3,
      othersTransmission: 0,
      testedPregnant: 230479,
      confirmedPregnant: 2652,
      pregnantOne: null,
      pregnantTwo: null,
      pregnantThree: null,
      percentConfirmedPregnant: 1.2,
      pregnantInTreatment: 5,
      percentPregnantInTreatment: 0.0,
      youngTested: 34841,
      youngConfirmed: 485,
      youngOne: null,
      youngTwo: null,
      youngThree: null,
      percentYoungConfirmed: 1.4,
      youngInTreatment: 105,
      percentYoungInTreatment: 21.6,
      tested: [],
      genres: [],
    },
  },
  questions: {
    argentina: [
      "yes",
      "yes",
      "yes",
      null,
      "yes",
      "yes",
      "yes",
      "yes",
      "yes",
      "yes",
      "yes",
      "yes",
      "yes",
      "no",
      "yes",
      "yes",
    ],
    brasil: [
      "yes",
      "no",
      "yes",
      "no",
      "yes",
      "no",
      "no",
      "yes",
      "yes",
      "yes",
      "yes",
      "yes",
      "yes",
      "no",
      "yes",
      "yes",
    ],
    colombia: [
      "yes",
      "no",
      "yes",
      null,
      "no",
      "yes",
      null,
      "yes",
      "yes",
      "yes",
      "yes",
      "yes",
      "no",
      "no",
      "yes",
      "yes",
    ],
    paraguai: [
      "yes",
      "yes",
      "yes",
      "yes",
      "yes",
      "yes",
      "no",
      "yes",
      "no",
      "no",
      "yes",
      "yes",
      "no",
      "yes",
      "no",
      "yes",
    ],
    reinounido: [
      "yes",
      "no",
      "yes",
      "yes",
      "no",
      "no",
      "no",
      "yes",
      "no",
      "no",
      "yes",
      null,
      "yes",
      null,
      "no",
      "no",
    ],
    guatemala: [
      "no",
      "yes",
      "yes",
      "no",
      "no",
      "yes",
      "no",
      "yes",
      "no",
      "no",
      "yes",
      "no",
      "yes",
      "no",
      "no",
      "yes",
    ],
    suica: [
      "no",
      "no",
      "yes",
      "yes",
      "no",
      "no",
      "no",
      "no",
      "no",
      "yes",
      "no",
      null,
      "no",
      null,
      "no",
      "no",
    ],
  },
};

export default function Data() {
  const getData = (year, country) => {
    return mainData[year][country];
  };
  const getQuestions = () => {
    return mainData.questions;
  };
  return {
    getData,
    getQuestions,
  };
}
