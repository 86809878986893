import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Translator from "../../libs/translator";

import Spacer from "../../components/spacer";

import WhiteBox from "../white_box";

const Holder = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;

  @media (max-width: 768px) {
  }
`;

const Text = styled.div`
  font-size: 32px;
  font-family: "Tiempos Headline";
  strong {
    font-family: "Tiempos Headline";
    font-weight: bold;
    font-size: 32px;
  }

  @media (max-width: 768px) {
    font-size: 24px;
    strong {
      font-size: 24px;
    }
  }
`;

const Big = styled.div`
  color: #404751;
  font-size: 152px;
  font-style: normal;
  font-weight: 900;
  line-height: 50%;
  letter-spacing: -4.56px;

  @media (max-width: 768px) {
    font-size: 72px;
  }
`;
const Small = styled.span`
  color: #404751;
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
  font-family: "Tiempos Headline";

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const Left = styled.div`
  width: 33%;
  padding-right: 16px;

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0px;
  }
`;
const Right = styled.div`
  width: 66%;
  position: relative;
  height: fit-content;

  @media (max-width: 768px) {
    width: 0px;
  }
`;

const Source = styled.div`
  position: absolute;
  color: #404751;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
`;

const MapHolder = styled.div`
  position: relative;
`;

const MapBox = styled.div`
  position: relative;
`;

const MapImage = styled.img`
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
`;

const End = styled.div``;

export default function Map() {
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  const [property, setProperty] = useState("");

  const floater = () => {
    const topPosition = document.getElementById("sidebar").offsetTop - 80;
    const floatingDivHeight =
      document.getElementById("floating-div").offsetHeight;

    // mobile
    if (width < 768) {
      // width of the box
      document.getElementById("mapbox").style.width = `${
        document.getElementById("left").offsetWidth
      }px`;

      // height of the box
      document.getElementById("mapbox").style.height = `${
        (document.getElementById("mapbox").offsetWidth * 1162) / 1746
      }px`;
    } else {
      // width of the box
      document.getElementById("mapbox").style.width = `${
        document.getElementById("sidebar").offsetWidth
      }px`;

      // height of the box
      document.getElementById("mapbox").style.height = `${
        (document.getElementById("mapbox").offsetWidth * 1162) / 1746
      }px`;
    }

    const footerFromTop = document.getElementById("footer").offsetTop - 80;
    const absPosition = footerFromTop - floatingDivHeight - 20;

    if (window.scrollY > topPosition && window.scrollY < absPosition) {
      setProperty("sticky");
      document.getElementById("second").style.opacity =
        (window.scrollY - topPosition) /
        (topPosition - document.getElementById("mapbox").offsetHeight);
    } else if (window.scrollY > topPosition && window.scrollY > absPosition) {
      setProperty("abs");
    } else {
      setProperty("");
    }
  };

  function handleWindowSizeChange() {
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
    floater();
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    window.addEventListener("load", handleWindowSizeChange);
    window.addEventListener("scroll", floater);
    floater();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Holder>
        <Left id="left">
          <WhiteBox
            paddingLeftRight={width < 768 ? 24 : 40}
            paddingUpBottom={width < 768 ? 24 : 40}
            radius={12}
            height={width < 768 ? (height / 4) * 2 : height - 80}
            marginRight={width < 768 ? 0 : 32}
          >
            <Text>
              <Translator path="home.map.text1" />
            </Text>
            <Source
              style={{
                left: width < 768 ? 24 : 40,
                bottom: width < 768 ? 24 : 40,
              }}
            >
              <Translator path="home.map.source1" />
            </Source>
          </WhiteBox>
          <Spacer height={width < 768 ? 16 : 32} />
          {width < 768 && (
            <>
              <MapBox id="mapbox">
                <MapImage src="/img/home/map_1.svg" />
                <MapImage
                  src="/img/home/map_2.svg"
                  id="second"
                  style={{ opacity: 0 }}
                />
              </MapBox>
              <Spacer height={16} />
            </>
          )}
          <WhiteBox
            paddingLeftRight={width < 768 ? 24 : 40}
            paddingUpBottom={width < 768 ? 24 : 40}
            radius={12}
            height={width < 768 ? (height / 4) * 2 : height - 80}
            marginRight={width < 768 ? 0 : 32}
          >
            <Text>
              <Big>
                70<Small>%</Small>
              </Big>
              <Translator path="home.map.text2" />
            </Text>
            <Source
              style={{
                left: width < 768 ? 24 : 40,
                bottom: width < 768 ? 24 : 40,
              }}
            >
              <Translator path="home.map.source2" />
            </Source>
          </WhiteBox>
        </Left>
        <Right
          id="sidebar"
          style={{
            height:
              (width < 768 ? (height / 4) * 2 : height) * 2 - 80 - 32 - 16,
          }}
        >
          <MapHolder id="floating-div" className={property}>
            {width > 768 && (
              <MapBox id="mapbox">
                <MapImage src="/img/home/map_1.svg" />
                <MapImage
                  src="/img/home/map_2.svg"
                  id="second"
                  style={{ opacity: 0 }}
                />
              </MapBox>
            )}
          </MapHolder>
        </Right>
      </Holder>
      <End id="footer" />
    </>
  );
}
