import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { Tooltip } from "react-tooltip";

import TranslatorText from "../../../libs/translator_text";
import Translator from "../../../libs/translator";

import Data from "../../../libs/data";

import Boolean from "../questions/boolean";
import Becker from "../questions/becker";
import Dots from "../questions/dots";
import Pie from "../questions/pie";
import Bars from "../questions/bars";
import Scroller from "../questions/scroller";
import Bottle from "../questions/bottle";
import Ball from "../questions/ball";
import Counter from "../../../libs/counter";

import Helper from "../../../libs/helper";
import PieCustom from "../questions/pieCustom";

const Holder = styled.div`
  @media (max-width: 768px) {
  }
`;

const Header = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding-bottom: 32px;

  @media (max-width: 1024px) {
    display: block;
  }
`;

const HeaderLeft = styled.div`
  width: 100%;
`;

const HeaderLeftTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderLeftContent = styled.div`
  width: 100%;
  padding: 40px 0px;
  display: flex;
  align-items: start;
  justify-content: end;
`;

const HeaderLeftDescription = styled.div`
  width: 75%;
  * {
    color: #404751;
    font-size: 20px;
    font-weight: 400;
    line-height: 150%; /* 30px */
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const HeaderLeftData = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Number = styled.div`
  color: #404751;
  font-family: "Tiempos Headline";
  font-size: 54px;
  font-style: normal;
  font-weight: 400;
  line-height: 84%;
  /* letter-spacing: -2.7px; */
  text-transform: uppercase;

  @media (max-width: 1024px) {
    font-size: 24px;
  }
`;

const Name = styled.div`
  color: #404751;
  font-size: 54px;
  font-weight: 700;
  line-height: 84%;
  /* letter-spacing: -2.7px; */
  text-transform: uppercase;

  @media (max-width: 1024px) {
    font-size: 24px;
  }
`;

const Line = styled.img`
  margin-left: 24px;
  margin-right: 24px;
  /* transform: translateY(-20px); */

  @media (max-width: 768px) {
    /* transform: translateY(-10px); */
    margin-left: 16px;
    margin-right: 16px;

    width: 32px;
    height: 4px;
  }
`;

const HeaderYear = styled.div``;

const Years = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 10px 24px;
  border-radius: 100px;
  cursor: pointer;
  margin-left: 32px;
  font-size: 16px;
`;

const ArrowDown = styled.img`
  margin-left: 8px;
`;

const YearSelector = styled.div`
  color: rgba(255, 255, 255, 0.87);
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 210%;
  cursor: pointer;
  padding: 4px 0px;

  &:hover,
  &.selected {
    color: #ef642e;
  }
`;

const HeaderRight = styled.div`
  width: 100%;
  text-align: right;
  max-width: 100%;
  padding-left: 32px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const HeaderFlag = styled.img`
  width: 438px;
  height: 438px;
  border-radius: 12px;
  overflow: hidden;
  max-width: 100%;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const HeaderFlagMobile = styled.img`
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  display: none;
  @media (max-width: 1024px) {
    display: block;
    margin-bottom: 56px;
  }
`;

const Bar = styled.div`
  /* height: 80px; */
  width: 100%;
  left: 0px;
  background-color: #f4f3f0;
  z-index: 99;
`;

const BarCentered = styled.div`
  /* height: 80px; */
  width: 100%;
  max-width: 1312px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Content = styled.div`
  width: 100%;
`;

const ContentBlock = styled.div`
  /* height: 300px; */
  /* padding-bottom: 32px; */
  width: 100%;

  &.last {
    padding-bottom: 0px;
  }
`;

const MiniFlag = styled.div`
  width: 90px;
  min-width: 90px;
  margin-right: 24px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  overflow: hidden;
  background-color: #fff;
`;

const MiniFlagImage = styled.img`
  width: 40px;
  height: 40px;
`;

const MiniFlagCode = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #404751;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 84%;
  letter-spacing: -0.32px;
  cursor: pointer;
`;

const MenuItem = styled.div`
  border-radius: 60px;
  background: #4f5867;
  padding: 12px 24px;
  background-color: #fff;
  text-transform: uppercase;
  min-width: fit-content;
  width: 100%;
  text-align: center;
  margin: 0px 4px;

  color: #404751;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 84%;
  letter-spacing: -0.32px;
  cursor: pointer;

  &.invisible {
    display: none;
  }

  &.selected,
  &:hover {
    color: #fff;
    background-color: #4f5867;
  }
`;

const Footer = styled.div``;

export default function Country({
  number,
  name,
  flag,
  code,
  description,
  age,
  hidePercentage,
}) {
  const [width, setWidth] = useState(window.innerWidth);

  const [property, setProperty] = useState("");
  const [floating, setFloating] = useState(false);

  const helper = Helper();

  const floater = () => {
    const headerheight = document.getElementById(`${flag}_header`).offsetHeight;

    const topPosition =
      document.getElementById(`${flag}_holder`).offsetTop + headerheight - 80;

    const floatingDivHeight = document.getElementById(
      `${flag}_bar`
    ).offsetHeight;

    const footerFromTop =
      document.getElementById(`${flag}_footer`).offsetTop - 80;
    const absPosition = footerFromTop - floatingDivHeight - 20;

    if (window.scrollY > topPosition && window.scrollY < absPosition) {
      setProperty("sticky");
      if (width >= 1350) setFloating(true);
    } else if (window.scrollY > topPosition && window.scrollY > absPosition) {
      setProperty("abs");
      if (width >= 1350) setFloating(true);
    } else {
      setProperty("");
      if (width >= 1350) setFloating(false);
    }

    for (let index = 0; index < 6; index++) {
      const _top =
        document.getElementById(`${flag}_area_${index}`).offsetTop -
        window.scrollY;
      if (_top < window.innerHeight / 3) {
        setSelected(index);
      }
    }
  };
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    floater();
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    window.addEventListener("load", handleWindowSizeChange);
    window.addEventListener("scroll", floater);
    floater();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToLink = (link) => {
    console.log(link);
    try {
      const position = document.getElementById(link).getBoundingClientRect();
      window.scrollTo({
        left: position.left,
        top: position.top + window.scrollY - 160,
        behavior: "smooth",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const [selected, setSelected] = useState(0);

  const _data = Data();

  // data
  const [selectedYear, setSelectedYear] = useState(2023);
  const years = [2023, 2022, 2021];
  const [data, setData] = useState({});
  const questions = _data.getQuestions();

  useEffect(() => {
    const __data = _data.getData(selectedYear, flag);
    setData(__data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear]);

  const c = Counter(0);

  return (
    <Holder id={`${flag}_holder`}>
      <HeaderFlagMobile src={`/img/home/flags/${flag}_hover.svg`} />
      <Header id={`${flag}_header`}>
        <HeaderLeft>
          <HeaderLeftTitle>
            <HeaderText>
              <Number>{number}</Number>
              <Line src="/img/countries/line_country.svg" />
              <Name>{name}</Name>
            </HeaderText>
            <HeaderYear>
              <Years data-tooltip-id={`${flag}_year`}>
                {selectedYear}
                <ArrowDown src="/img/header/arrow_down.svg" />
              </Years>
              <Tooltip
                id={`${flag}_year`}
                place="bottom"
                className="tooltip"
                clickable
              >
                {years.map((year) => {
                  return (
                    <YearSelector
                      key={year}
                      onClick={() => {
                        setSelectedYear(year);
                      }}
                      className={`${selectedYear === year && "selected"}`}
                    >
                      {year}
                    </YearSelector>
                  );
                })}
              </Tooltip>
            </HeaderYear>
          </HeaderLeftTitle>
          <HeaderLeftContent>
            <HeaderLeftDescription>
              <Translator path={description} />
              <br />
              <br />
              {data.population && (
                <HeaderLeftData>
                  <div>
                    <Translator path="countries.intro.label.population" />:{" "}
                    <Translator
                      path={`countries.intro.data.population.${flag}`}
                    />
                  </div>
                </HeaderLeftData>
              )}
              {data.prevalence && (
                <HeaderLeftData>
                  <div>
                    <Translator path="countries.intro.label.prevalence" />:{" "}
                    <Translator
                      path={`countries.intro.data.prevalence.${flag}`}
                    />
                  </div>
                </HeaderLeftData>
              )}
              {data.riskPopulation && (
                <HeaderLeftData>
                  <div>
                    <Translator path="countries.intro.label.risk_population" />:{" "}
                    <Translator
                      path={`countries.intro.data.riskPopulation.${flag}`}
                    />
                  </div>
                </HeaderLeftData>
              )}
              {data.vectorialRist && (
                <HeaderLeftData>
                  <div>
                    <Translator path="countries.intro.label.risk_vectorial" />:{" "}
                    <Translator
                      path={`countries.intro.data.riskVectorial.${flag}`}
                    />
                  </div>
                </HeaderLeftData>
              )}
            </HeaderLeftDescription>
          </HeaderLeftContent>
        </HeaderLeft>
        <HeaderRight>
          <HeaderFlag src={`/img/home/flags/${flag}_hover.svg`} />
        </HeaderRight>
      </Header>
      <Bar id={`${flag}_bar`} className={`${property} desktopCustom`}>
        <BarCentered>
          <MiniFlag>
            <MiniFlagImage src={`/img/home/flags/${flag}_hover.svg`} />
            <MiniFlagCode
              onClick={() => {
                goToLink("flags_menu");
              }}
            >
              {code}
            </MiniFlagCode>
          </MiniFlag>
          <MenuItem
            key={0}
            className={`${selected === 0 && "selected"} ${
              data.peopleTested ||
              data.percentInTreatment ||
              data.acuteCases ||
              data.cronicCases ||
              data.oralTransmission ||
              data.vectorTransmission ||
              data.verticalTransmission
                ? ``
                : `invisible`
            }`}
            onClick={() => {
              goToLink(`${flag}_area_${0}`);
              setSelected(0);
            }}
          >
            {TranslatorText("countries.menu.population")}
          </MenuItem>

          <MenuItem
            key={1}
            className={`${selected === 1 && "selected"} ${
              data.testedPregnant ||
              data.confirmedPregnant ||
              data.percentPregnantInTreatment
                ? ``
                : `invisible`
            }`}
            onClick={() => {
              goToLink(`${flag}_area_${1}`);
              setSelected(1);
            }}
          >
            {TranslatorText("countries.menu.pregnant")}
          </MenuItem>
          <MenuItem
            key={2}
            className={`${selected === 2 && "selected"} ${
              data.youngTested || data.percentYoungInTreatment
                ? ``
                : `invisible`
            }`}
            onClick={() => {
              goToLink(`${flag}_area_${2}`);
              setSelected(2);
            }}
          >
            {TranslatorText("countries.menu.children")}
          </MenuItem>
          <MenuItem
            key={3}
            className={`${selected === 3 && "selected"}`}
            onClick={() => {
              goToLink(`${flag}_area_${3}`);
              setSelected(3);
            }}
          >
            {TranslatorText("countries.menu.assistance")}
          </MenuItem>
          <MenuItem
            key={4}
            className={`${selected === 4 && "selected"}`}
            onClick={() => {
              goToLink(`${flag}_area_${4}`);
              setSelected(4);
            }}
          >
            {TranslatorText("countries.menu.actions")}
          </MenuItem>
          <MenuItem
            key={5}
            className={`${selected === 5 && "selected"}`}
            onClick={() => {
              goToLink(`${flag}_area_${5}`);
              setSelected(5);
            }}
          >
            {TranslatorText("countries.menu.more")}
          </MenuItem>
        </BarCentered>
      </Bar>
      <Content style={{ paddingTop: floating ? 88 : 32 }}>
        <ContentBlock id={`${flag}_area_0`}>
          <PieCustom
            cNumber={number}
            number={c.getNext(data.tested && data.tested.length > 0)}
            title={TranslatorText(`questions.tested.${selectedYear}.${flag}.q`)}
            labels={TranslatorText(
              `questions.tested.${selectedYear}.${flag}.l`
            )}
            values={data.tested}
          />
          <PieCustom
            cNumber={number}
            number={c.getNext(data.genres && data.genres.length > 0)}
            title={TranslatorText(`questions.genres.${selectedYear}.${flag}.q`)}
            labels={TranslatorText(
              `questions.genres.${selectedYear}.${flag}.l`
            )}
            values={data.genres}
          />
          <Becker
            flag={flag}
            cNumber={number}
            number={c.getNext(data.peopleTested)}
            title={TranslatorText(
              `questions.1.${selectedYear}.${flag}`,
              data.confirmedOne,
              data.confirmedTwo,
              data.confirmedThree
            )}
            topValue={data.peopleTested}
            topUnit={TranslatorText(`tooltips.1a.${selectedYear}.${flag}`)}
            topLabel={TranslatorText("tooltips.1b")}
            bottomValue={data.confirmedCases}
            bottomUnit={TranslatorText("tooltips.1c")}
            bottomLabel={TranslatorText("tooltips.1d")}
            womanLabel={TranslatorText("tooltips.1e")}
            manLabel={TranslatorText("tooltips.1f")}
            othersLabel={TranslatorText("tooltips.1g")}
            woman={0}
            man={0}
            others={0}
            tooltip={`${data.peopleTestedOne} ${TranslatorText(
              "tooltips.1b"
            )}, ${data.peopleTestedTwo} ${TranslatorText("tooltips.1d")}`}
          />
          <Pie
            cNumber={number}
            number={c.getNext(data.acuteCases || data.cronicCases)}
            title={TranslatorText(
              `questions.3.${selectedYear}.${flag}`,
              data.cronicOne,
              data.cronicTwo
            )}
            percent1={data.acuteCases}
            percent2={data.cronicCases}
            label1={TranslatorText("tooltips.3a", data.acuteCases)}
            label2={TranslatorText("tooltips.3b")}
            extraTooltip={TranslatorText(
              `questions.3t.${selectedYear}.${flag}`
            )}
          />
          <Bars
            last
            cNumber={number}
            number={c.getNext(
              data.oralTransmission ||
                data.vectorTransmission ||
                data.verticalTransmission ||
                data.othersTransmission
            )}
            title={TranslatorText("questions.4")}
            flag={flag}
            value1={data.oralTransmission}
            percent1={(
              (data.oralTransmission / data.confirmedCases) *
              100
            ).toFixed(2)}
            label1={TranslatorText("tooltips.4a")}
            value2={data.vectorTransmission}
            percent2={(
              (data.vectorTransmission / data.confirmedCases) *
              100
            ).toFixed(2)}
            label2={TranslatorText("tooltips.4b")}
            value3={data.verticalTransmission}
            percent3={(
              (data.verticalTransmission / data.confirmedCases) *
              100
            ).toFixed(2)}
            label3={TranslatorText("tooltips.4c")}
            value4={data.othersTransmission}
            percent4={(
              (data.othersTransmission / data.confirmedCases) *
              100
            ).toFixed(2)}
            label4={TranslatorText("tooltips.4d")}
          />
          <Dots
            flag={flag}
            cNumber={number}
            number={c.getNext(data.percentInTreatment)}
            percent={data.percentInTreatment}
            title={TranslatorText("questions.2", data.inTreatment)}
            label={TranslatorText("tooltips.2")}
            tooltip={TranslatorText(
              `data.${selectedYear}.percentInTreatment.${flag}`
            )}
            extraTooltip={TranslatorText(
              `questions.2t.${selectedYear}.${flag}`
            )}
          />
        </ContentBlock>
        <ContentBlock id={`${flag}_area_1`}>
          <Bottle
            cNumber={number}
            number={c.getNext(data.testedPregnant)}
            title={TranslatorText(
              `questions.5.${selectedYear}.${flag}`,
              data.pregnantOne,
              data.pregnantTwo
            )}
            topValue={data.peopleTested}
            topUnit={TranslatorText("tooltips.5a")}
            topLabel={TranslatorText("tooltips.5b")}
            bottomValue={data.testedPregnant}
            bottomUnit={TranslatorText("tooltips.5c")}
            bottomLabel={TranslatorText("tooltips.5d")}
            womanLabel={TranslatorText("tooltips.5e")}
            manLabel={TranslatorText("tooltips.5f")}
            othersLabel={TranslatorText("tooltips.5g")}
            woman={0}
            man={0}
            others={0}
            flag={flag}
            tooltip={`${data.peopleTested} ${TranslatorText("tooltips.5b")}, ${
              data.testedPregnant
            } ${TranslatorText("tooltips.5d")}`}
          />
          <Becker
            flag={flag}
            cNumber={number}
            number={c.getNext(data.confirmedPregnant)}
            title={TranslatorText(
              `questions.6.${selectedYear}.${flag}`,
              helper.numberRound(data.testedPregnant),
              data.pregnantThree
            )}
            topValue={data.testedPregnant}
            topUnit={TranslatorText("tooltips.6a")}
            topLabel={TranslatorText("tooltips.6b")}
            bottomValue={data.confirmedPregnant}
            bottomUnit={TranslatorText("tooltips.6c")}
            bottomLabel={TranslatorText("tooltips.6d")}
            womanLabel={TranslatorText("tooltips.6e")}
            manLabel={TranslatorText("tooltips.6f")}
            othersLabel={TranslatorText("tooltips.6g")}
            woman={0}
            man={0}
            others={0}
            tooltip={`${data.testedPregnant} ${TranslatorText(
              "tooltips.6b"
            )}, ${data.confirmedPregnant} ${TranslatorText("tooltips.6d")}`}
          />
          <Dots
            last
            selectedYear={selectedYear}
            cNumber={number}
            number={c.getNext(data.percentPregnantInTreatment)}
            percent={data.percentPregnantInTreatment}
            title={TranslatorText(
              `questions.7.${selectedYear}.${flag}`,
              data.pregnantInTreatment
            )}
            flag={flag}
            label={TranslatorText(`tooltips.7.${selectedYear}.${flag}`)}
            tooltip={TranslatorText(`questions.7t.${selectedYear}.${flag}`)}
            hidePercentage={
              selectedYear === 2023 && flag === "brasil"
                ? hidePercentage
                : false
            }
          />
        </ContentBlock>
        <ContentBlock id={`${flag}_area_2`}>
          <Ball
            cNumber={number}
            number={c.getNext(data.youngTested)}
            title={TranslatorText(
              `questions.8.${selectedYear}.${flag}`,
              data.youngOne,
              data.youngTwo,
              age
            )}
            topValue={data.peopleTested}
            topUnit={TranslatorText("tooltips.8a")}
            topLabel={TranslatorText("tooltips.8b")}
            bottomValue={data.youngTested}
            bottomUnit={TranslatorText("tooltips.8c")}
            bottomLabel={TranslatorText("tooltips.8d", null, null, age)}
            womanLabel={TranslatorText("tooltips.8e")}
            manLabel={TranslatorText("tooltips.8f")}
            woman={0}
            man={0}
            others={0}
            flag={flag}
            tooltip={`${data.peopleTested} ${TranslatorText("tooltips.8b")}, ${
              data.youngTested
            } ${TranslatorText("tooltips.8d", null, null, age)}`}
          />
          <Becker
            flag={flag}
            cNumber={number}
            number={c.getNext(data.youngTested)}
            title={TranslatorText(
              `questions.9.${selectedYear}.${flag}`,
              data.youngThree,
              null,
              age
            )}
            topValue={data.youngTested}
            topUnit={TranslatorText("tooltips.9a")}
            topLabel={TranslatorText("tooltips.9b", null, null, age)}
            bottomValue={data.youngConfirmed}
            bottomUnit={TranslatorText("tooltips.9c")}
            bottomLabel={TranslatorText("tooltips.9d", null, null, age)}
            womanLabel={TranslatorText("tooltips.9e")}
            manLabel={TranslatorText("tooltips.9f")}
            othersLabel={TranslatorText("tooltips.9g")}
            woman={0}
            man={0}
            others={0}
            tooltip={`${data.youngTested} ${TranslatorText(
              "tooltips.9b",
              null,
              null,
              age
            )}, ${data.youngConfirmed} ${TranslatorText(
              "tooltips.9d",
              null,
              null,
              age
            )}`}
          />
          <Dots
            last
            cNumber={number}
            number={c.getNext(data.percentYoungInTreatment)}
            percent={data.percentYoungInTreatment}
            title={TranslatorText(
              `questions.10.${selectedYear}.${flag}`,
              data.youngInTreatment,
              null,
              age
            )}
            label={TranslatorText("tooltips.10")}
          />
        </ContentBlock>
        <ContentBlock id={`${flag}_area_3`}>
          <Boolean
            cNumber={number}
            number={c.getNext(questions[flag][0])}
            flag={flag}
            title={TranslatorText("questions.11")}
            checked={questions[flag][0]}
          />
          <Boolean
            cNumber={number}
            number={c.getNext(questions[flag][1])}
            flag={flag}
            title={TranslatorText("questions.12")}
            checked={questions[flag][1]}
            tooltip={TranslatorText(`questions.12t.${flag}`)}
          />
          <Boolean
            cNumber={number}
            number={c.getNext(questions[flag][2])}
            flag={flag}
            title={TranslatorText("questions.13")}
            checked={questions[flag][2]}
            tooltip={TranslatorText(`questions.13t.${flag}`)}
          />
          <Boolean
            cNumber={number}
            number={c.getNext(questions[flag][3])}
            flag={flag}
            title={TranslatorText("questions.14")}
            checked={questions[flag][3]}
            tooltip={TranslatorText(`questions.14t.${flag}`)}
          />
          <Boolean
            last
            flag={flag}
            cNumber={number}
            number={c.getNext(questions[flag][4])}
            title={TranslatorText("questions.15")}
            checked={questions[flag][4]}
            tooltip={TranslatorText(`questions.15t.${flag}`)}
          />
        </ContentBlock>
        <ContentBlock id={`${flag}_area_4`}>
          <Boolean
            cNumber={number}
            number={c.getNext(questions[flag][5])}
            flag={flag}
            title={TranslatorText("questions.16")}
            checked={questions[flag][5]}
          />
          <Boolean
            cNumber={number}
            number={c.getNext(questions[flag][6])}
            flag={flag}
            title={TranslatorText("questions.17")}
            checked={questions[flag][6]}
            tooltip={TranslatorText(`questions.17t.${flag}`)}
          />
          <Boolean
            cNumber={number}
            number={c.getNext(questions[flag][7])}
            flag={flag}
            title={TranslatorText("questions.18")}
            checked={questions[flag][7]}
            tooltip={TranslatorText(`questions.18t.${flag}`)}
          />
          <Boolean
            cNumber={number}
            number={c.getNext(questions[flag][8])}
            flag={flag}
            title={TranslatorText("questions.19")}
            checked={questions[flag][8]}
          />
          <Boolean
            cNumber={number}
            number={c.getNext(questions[flag][9])}
            flag={flag}
            title={TranslatorText("questions.20")}
            checked={questions[flag][9]}
            tooltip={TranslatorText(`questions.20t.${flag}`)}
          />
          <Boolean
            cNumber={number}
            number={c.getNext(questions[flag][10])}
            flag={flag}
            title={TranslatorText("questions.21")}
            checked={questions[flag][10]}
            tooltip={TranslatorText(`questions.21t.${flag}`)}
          />
          <Boolean
            cNumber={number}
            number={c.getNext(questions[flag][11])}
            flag={flag}
            title={TranslatorText("questions.22")}
            checked={questions[flag][11]}
            tooltip={`${TranslatorText(`questions.22t.general`)} ${
              TranslatorText(`questions.22t.${flag}`)
                ? TranslatorText(`questions.22t.${flag}`)
                : ``
            }`}
          />
          <Boolean
            cNumber={number}
            number={c.getNext(questions[flag][12])}
            flag={flag}
            title={TranslatorText("questions.23")}
            checked={questions[flag][12]}
            tooltip={TranslatorText(`questions.23t.${flag}`)}
          />
          <Boolean
            cNumber={number}
            number={c.getNext(questions[flag][13])}
            flag={flag}
            title={TranslatorText("questions.24")}
            checked={questions[flag][13]}
            tooltip={TranslatorText(`questions.24t.${flag}`)}
          />
          <Boolean
            cNumber={number}
            number={c.getNext(questions[flag][14])}
            flag={flag}
            title={TranslatorText("questions.25")}
            checked={questions[flag][14]}
            tooltip={TranslatorText(`questions.25t.${flag}`)}
          />
          <Boolean
            last
            flag={flag}
            cNumber={number}
            number={c.getNext(questions[flag][15])}
            title={TranslatorText("questions.26")}
            checked={questions[flag][15]}
            tooltip={TranslatorText(`questions.26t.${flag}`)}
          />
        </ContentBlock>
        <ContentBlock id={`${flag}_area_5`} className="last">
          <Scroller
            cNumber={number}
            number={c.getNext(true)}
            title={TranslatorText("questions.27")}
            description={TranslatorText(`countries.${flag}.questions.moreInfo`)}
            source={TranslatorText(`countries.${flag}.questions.source`)}
          />
        </ContentBlock>
      </Content>
      <Footer id={`${flag}_footer`}></Footer>
    </Holder>
  );
}
