import React, { useState, useEffect } from "react";

import Page from "../components/page";
import MainHeader from "../components/main_header";
import Spacer from "../components/spacer";
import WhiteBox from "../components/white_box";

import Intro from "../components/home/intro";

import AnimatedBanner from "../components/home/animated_banner";
import Phrase from "../components/home/phrase";
import Map from "../components/home/map";

import VideoTestimonial from "../components/home/video_testimonial";

import Translator from "../libs/translator";
import Charts from "../components/home/charts";
import Years from "../components/home/years";
import PoorData from "../components/home/poor_data";
import Flags from "../components/home/flags";
import Slideshow from "../components/slideshow";
import Footer from "../components/footer";
import Argentina from "../components/countries/argentina";
import Brasil from "../components/countries/brasil";
import Colombia from "../components/countries/colombia";
import Guatemala from "../components/countries/guatemala";
import Paraguai from "../components/countries/paraguai";
import ReinoUnido from "../components/countries/reinounido";
import Suica from "../components/countries/suica";

export default function Home() {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    window.addEventListener("load", handleWindowSizeChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* <Malandro /> */}
      <MainHeader />
      <Spacer height={80} />
      <Page>
        <WhiteBox
          paddingLeftRight={width < 768 ? 24 : 40}
          paddingUpBottom={width < 768 ? 24 : 40}
          radius={12}
        >
          <Intro />
        </WhiteBox>
        <Spacer height={32} />
        <AnimatedBanner />
        <Spacer height={32} />
        <WhiteBox
          paddingLeftRight={width < 768 ? 24 : 40}
          paddingUpBottom={width < 768 ? 24 : 40}
          radius={12}
        >
          <Phrase>
            <Translator path="home.phrase1" />
          </Phrase>
        </WhiteBox>
        <Spacer height={32} />
        <AnimatedBanner big />
        <Spacer height={32} />
        <Map />
        <Spacer height={104} />
        <Phrase>
          <Translator path="home.phrase2" />
        </Phrase>
        <Spacer height={104} />
        <WhiteBox paddingLeftRight={0} paddingUpBottom={0} radius={12}>
          <VideoTestimonial
            title="home.testimonial.text"
            name="home.testimonial.name"
            label="home.testimonial.label"
            width={"40%"}
            height={"100%"}
            cover={"/img/home/video_cover.jpg"}
          />
        </WhiteBox>
        <Spacer height={104} />
        <Phrase>
          <Translator path="home.phrase3" />
        </Phrase>
        <Spacer height={64} />
        <Charts width={width} />
        <Spacer height={32} />
        <Years />
        <Spacer height={32} />
        <PoorData />
        <Spacer height={64} />
      </Page>
      <Slideshow />
      <Page>
        <Spacer height={224} />
        <Flags />
        <Spacer height={224} />
        <Argentina />
        <Spacer height={224} />
        <Brasil />
        <Spacer height={224} />
        <Colombia />
        <Spacer height={224} />
        <Guatemala />
        <Spacer height={224} />
        <Paraguai />
        <Spacer height={224} />
        <ReinoUnido />
        <Spacer height={224} />
        <Suica />
      </Page>
      <Spacer height={width < 768 ? 32 : 64} />
      <Footer />
    </>
  );
}
