import React from "react";
import styled from "styled-components";

import translatorText from "../../libs/translator_text";

import Country from "./shared/country";

const Holder = styled.div`
  @media (max-width: 768px) {
  }
`;

export default function Suica() {
  return (
    <Holder>
      <Country
        number={"07"}
        name={translatorText("countries.suica.name")}
        flag={"suica"}
        code={"CH"}
        description={"countries.suica.description"}
      />
    </Holder>
  );
}
