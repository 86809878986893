import React from "react";
import styled from "styled-components";

import translatorText from "../../libs/translator_text";

import Country from "./shared/country";

const Holder = styled.div`
  @media (max-width: 768px) {
  }
`;

export default function Colombia() {
  return (
    <Holder>
      <Country
        number={"03"}
        name={translatorText("countries.colombia.name")}
        flag={"colombia"}
        code={"COL"}
        description={"countries.colombia.description"}
        age={17}
      />
    </Holder>
  );
}
