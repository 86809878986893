import React, { useState } from "react";
import styled from "styled-components";

import Translator from "../../../libs/translator";

const Holder = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  padding-bottom: 32px;

  @media (max-width: 768px) {
    display: block;
    height: auto;
  }
`;

const TitleHolder = styled.div`
  width: 40%;
  display: flex;
  align-items: start;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: 100%;
    display: block;
  }
`;

const Number = styled.div`
  width: 120px;
  height: 160px;
  padding: 40px;
  text-align: center;
  color: #404751;
  font-size: 32px;
  font-weight: 700;
  line-height: 84%; /* 26.88px */
  letter-spacing: -1.6px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    height: auto;
    padding: 24px;
    text-align: left;
  }
`;

const Title = styled.div`
  width: 100%;
  padding: 40px 0px;
  text-align: left;
  color: #404751;
  font-family: "Tiempos Headline";
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;

  strong {
    font-size: 32px;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    padding: 0px 24px 24px 24px;
    font-size: 20px;
    strong {
      font-size: 20px;
    }
  }
`;

const Description = styled.div`
  width: 60%;
  color: #404751;
  font-size: 20px;
  line-height: 150%;
  overflow: hidden;
  position: relative;
  transition: 0.5s;

  padding: 32px 32px 0px 32px;

  a {
    text-decoration: underline;
  }

  strong {
    font-weight: bold;
  }

  @media (max-width: 768px) {
    width: 100%;

    padding: 24px 24px 0px 24px;
  }
`;

const Gradient = styled.div`
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  width: 100%;
  height: 200px;
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: 1;
`;

const Button = styled.div`
  border-radius: 39px;
  background: #404751;
  width: fit-content;
  margin: 0 auto;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0px;
  z-index: 2;
  color: #fff;
  cursor: pointer;
  left: 50%;
  transform: translateX(-50%);
`;

const Arrow = styled.img`
  margin-left: 8px;
`;

export default function Scroller({
  cNumber,
  number,
  title,
  description,
  source,
}) {
  const [open, setOpen] = useState(false);

  return (
    <Holder>
      <TitleHolder>
        <Number>
          {`${parseInt(cNumber)}.`}
          {number}
        </Number>
        <Title>{title}</Title>
      </TitleHolder>
      <Description style={{ height: open ? "auto" : 300 }}>
        {description}
        <br />
        <br />
        {source}
        {!open && <Gradient />}
        {!open && (
          <Button
            onClick={() => {
              setOpen(true);
            }}
          >
            <Translator path="general.readMore" />
            <Arrow src="/img/about/arrow_down.svg" />
          </Button>
        )}
      </Description>
    </Holder>
  );
}
