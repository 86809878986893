import React, { useState, useEffect } from "react";

import Page from "../components/page";
import MainHeader from "../components/main_header";
import Spacer from "../components/spacer";
import WhiteBox from "../components/white_box";

import Intro from "../components/about/intro";

import AnimatedBanner from "../components/home/animated_banner";
import Content from "../components/about/content";
import Footer from "../components/footer";

export default function About() {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    window.addEventListener("load", handleWindowSizeChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MainHeader />
      <Spacer height={80} />
      <Page>
        <WhiteBox
          paddingLeftRight={width < 768 ? 24 : 40}
          paddingUpBottom={width < 768 ? 24 : 40}
          radius={12}
        >
          <Intro />
        </WhiteBox>
        <Spacer height={width < 768 ? 16 : 32} />
        <AnimatedBanner />
        <Spacer height={width < 768 ? 16 : 32} />
        <WhiteBox
          paddingLeftRight={width < 768 ? 24 : 40}
          paddingUpBottom={width < 768 ? 24 : 40}
          radius={12}
        >
          <Content />
        </WhiteBox>
      </Page>
      <Spacer height={width < 768 ? 32 : 64} />
      <Footer />
    </>
  );
}
