import React from "react";
import styled from "styled-components";

import Question from "../shared/question";

import { Tooltip } from "react-tooltip";

const Holder = styled.div`
  width: 100%;
  height: 460px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 32px;
  &.last {
    margin-bottom: 0px;
  }

  @media (max-width: 768px) {
    display: block;
    height: auto;
  }
`;

const Chart = styled.div`
  width: 100%;
  height: 460px;
  display: flex;
  align-items: start;
  justify-content: center;
  padding: 40px;

  @media (max-width: 768px) {
    padding: 24px;
  }
`;

const BarHolder = styled.div`
  width: 30%;
  height: 100%;
  position: relative;
`;

const Bar = styled.div`
  background-color: #404751;
  border-radius: 12px;
  position: absolute;
  bottom: 80px;
  left: 0px;
  width: 90%;

  &.one {
    background-color: #404751;
    min-height: 10%;
    max-height: 80%;
  }
  &.two {
    background-color: #727c51;
    min-height: 10%;
    max-height: 80%;
  }
  &.three {
    background-color: #ef642e;
    min-height: 10%;
    max-height: 80%;
  }
  &.four {
    background-color: #e3ccbc;
    min-height: 10%;
    max-height: 80%;
  }
`;

const BarPercent = styled.div`
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 8px;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -1px;
`;

const Label = styled.div`
  position: absolute;
  left: 0px;
  bottom: 0px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 90%;

  color: #404751;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -1px;
`;

export default function Bars({
  cNumber,
  number,
  flag,
  title,
  percent1,
  percent2,
  percent3,
  percent4,
  label1,
  label2,
  label3,
  label4,
  value1,
  value2,
  value3,
  value4,
  extraTooltip,
}) {
  return (
    (percent1 > 0 || percent2 > 0 || percent3 > 0 || percent4 > 0) && (
      <Holder className="bars">
        <Question
          cNumber={cNumber}
          number={number}
          title={title}
          tooltip={extraTooltip}
        >
          <Chart>
            {percent1 > 0 ? (
              <BarHolder>
                <Bar
                  className="one"
                  style={{ height: `${percent1}%` }}
                  data-tooltip-id={`${flag}_${number}_bar_1`}
                >
                  <BarPercent>{percent1}%</BarPercent>
                </Bar>
                <Label>{label1}</Label>
              </BarHolder>
            ) : null}
            {percent2 > 0 ? (
              <BarHolder>
                <Bar
                  className="two"
                  style={{ height: `${percent2}%` }}
                  data-tooltip-id={`${flag}_${number}_bar_2`}
                >
                  <BarPercent>{percent2}%</BarPercent>
                </Bar>
                <Label>{label2}</Label>
              </BarHolder>
            ) : null}
            {percent3 > 0 ? (
              <BarHolder>
                <Bar
                  className="three"
                  style={{ height: `${percent3}%` }}
                  data-tooltip-id={`${flag}_${number}_bar_3`}
                >
                  <BarPercent>{percent3}%</BarPercent>
                </Bar>
                <Label>{label3}</Label>
              </BarHolder>
            ) : null}
            {percent4 > 0 ? (
              <BarHolder>
                <Bar
                  className="four"
                  style={{ height: `${percent4}%` }}
                  data-tooltip-id={`${flag}_${number}_bar_4`}
                >
                  <BarPercent>{percent4}%</BarPercent>
                </Bar>
                <Label>{label4}</Label>
              </BarHolder>
            ) : null}
          </Chart>
          <Tooltip
            id={`${flag}_${number}_bar_1`}
            place="left"
            className="tooltip"
          >
            {value1}
          </Tooltip>
          <Tooltip
            id={`${flag}_${number}_bar_2`}
            place="left"
            className="tooltip"
          >
            {value2}
          </Tooltip>
          <Tooltip
            id={`${flag}_${number}_bar_3`}
            place="left"
            className="tooltip"
          >
            {value3}
          </Tooltip>
          <Tooltip
            id={`${flag}_${number}_bar_4`}
            place="left"
            className="tooltip"
          >
            {value4}
          </Tooltip>
        </Question>
      </Holder>
    )
  );
}
