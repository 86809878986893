import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./pages/home.js";
import About from "./pages/about.js";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="*" element={<Home />} />
    </Routes>
  );
}

export default App;
