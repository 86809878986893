import React from "react";
import styled from "styled-components";

const Holder = styled.div`
  padding: 0px 64px;
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;

  @media (max-width: 768px) {
    padding: 0px 16px;
  }
`;

export default function Page({ children }) {
  return <Holder>{children}</Holder>;
}
