import React, { useState } from "react";
import styled from "styled-components";

const Holder = styled.div`
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
  }
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;

  height: 100%;
`;

const Left = styled.div`
  width: 66%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;

  @media (max-width: 768px) {
    padding: 24px 16px 16px 16px;
  }
`;

const Number = styled.div`
  color: #404751;
  font-family: "Tiempos Headline";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 84%; /* 26.88px */
  letter-spacing: -0.64px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1440px) {
    font-size: 24px;
  }
`;

const Line = styled.img`
  margin-right: 8px;
  transition: 0.5s;
  width: 32px;
`;

const Country = styled.div`
  * {
    color: #404751;
    font-size: 32px;
    font-weight: 700;
    line-height: 84%;
    letter-spacing: -0.64px;
    text-transform: uppercase;
  }

  position: relative;
  height: 40px;
  overflow: hidden;
  width: 100%;
  top: 8px;

  @media (max-width: 1440px) {
    * {
      font-size: 20px;
    }
  }

  @media (max-width: 1280px) {
    * {
      font-size: 20px;
    }
  }

  @media (max-width: 768px) {
    * {
      font-size: 20px;
    }
  }
`;

const CountryLabel = styled.div`
  position: absolute;
  left: 0px;
  top: 6px;
  transition: 0.5s;
`;

const CountryLabelHover = styled.div`
  position: absolute;
  left: 0px;
  top: 35px;
  transition: 0.5s;
`;

const Right = styled.div`
  position: relative;
  width: 140px;
  height: 100%;

  @media (max-width: 768px) {
    width: 112px;
    height: 100%;
  }
`;

const FlagImage = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 1;
  transition: 0.5s;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

export default function Flag({
  empty,
  number,
  country,
  flag,
  flagHover,
  link,
}) {
  const [hover, setHover] = useState(false);

  const goToLink = () => {
    try {
      const position = document.getElementById(link).getBoundingClientRect();
      window.scrollTo({
        left: position.left,
        top: position.top + window.scrollY - 96,
        behavior: "smooth",
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Holder>
      {!empty && (
        <Box
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
          onTouchStart={() => {
            setHover(true);
          }}
          onTouchEnd={() => {
            setHover(false);
          }}
          onClick={goToLink}
        >
          <Left>
            <Number>
              <Line
                style={{ width: hover ? 32 : 0, marginRight: hover ? 8 : 0 }}
                src="/img/home/flags/line_hover.svg"
              />
              {number}
            </Number>
            <Country>
              <CountryLabel style={{ top: hover ? -45 : 6 }}>
                {country}
              </CountryLabel>
              <CountryLabelHover style={{ top: hover ? 6 : 45 }}>
                {country}
              </CountryLabelHover>
            </Country>
          </Left>
          <Right>
            <FlagImage
              style={{ backgroundImage: `url(/img/home/flags/${flag}.svg)` }}
            />
            <FlagImage
              style={{
                zIndex: 2,
                opacity: hover ? 1 : 0,
                backgroundImage: `url(/img/home/flags/${flagHover}.svg)`,
              }}
            />
          </Right>
        </Box>
      )}
    </Holder>
  );
}
