import React from "react";
import styled from "styled-components";

const Holder = styled.div`
  width: 100%;

  img {
    width: 100%;
  }
`;

export default function AnimatedBanner({ big }) {
  return (
    <Holder>
      {big ? (
        <img src="/img/home/animated_banner_big.svg" alt="Icons" />
      ) : (
        <img src="/img/home/animated_banner.svg" alt="Icons" />
      )}
    </Holder>
  );
}
