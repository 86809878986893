import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { useTranslation } from "react-i18next";
import Translator from "../libs/translator";

import { Tooltip } from "react-tooltip";

import Page from "./page";

const Holder = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 80px;
  z-index: 999;

  background-color: #f4f3f0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
  }
`;

const Spacer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  transition: 0.5s;
`;

const Name = styled.div`
  text-transform: uppercase;
  font-weight: bold;
`;
const Heart = styled.img`
  margin-left: 8px;
`;
const Eye = styled.img`
  margin-left: 8px;
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    font-size: 16px;
    font-weight: bold;
  }
`;

const Languages = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 10px 24px;
  border-radius: 100px;
  cursor: pointer;
  margin-left: 32px;
  font-size: 16px;
`;

const Globe = styled.img`
  margin-right: 8px;
`;
const ArrowDown = styled.img`
  margin-left: 8px;
`;

const LanguageSelector = styled.div`
  color: rgba(255, 255, 255, 0.87);
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 210%;
  cursor: pointer;
  padding: 4px 0px;

  &:hover {
    color: #ef642e;
  }
`;

export default function MainHeader() {
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const selectedLanguage = i18n.language;

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    window.addEventListener("load", handleWindowSizeChange);
  }, []);

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Holder>
      <Page>
        <Spacer>
          <Logo
            style={{
              opacity: scrollPosition > 5 ? 1 : 0,
              cursor: scrollPosition > 5 ? "pointer" : "default",
            }}
            onClick={
              scrollPosition > 5
                ? () => {
                    window.location = "/";
                  }
                : () => {}
            }
          >
            {width > 768 && (
              <Name>
                <Translator path="home.logo" />
              </Name>
            )}
            <Heart src="/img/header/heart.svg" />
            <Eye src="/img/header/eye.svg" />
          </Logo>
          <Menu>
            <a href="/#/about">
              {width <= 800 ? (
                <Translator path="home.about" />
              ) : (
                <Translator path="home.aboutObservatory" />
              )}
            </a>
            <Languages data-tooltip-id="languages">
              <Globe src="/img/header/globe.svg" />
              {width > 768 && <Translator path="language" />}
              <ArrowDown src="/img/header/arrow_down.svg" />
            </Languages>
            <Tooltip
              id="languages"
              place="bottom"
              className="tooltip"
              clickable
            >
              <LanguageSelector
                onClick={() => {
                  changeLanguage("pt-BR");
                }}
                className={`${selectedLanguage === "pt-BR" && "selected"}`}
              >
                <Translator path="home.languages.pt" />
              </LanguageSelector>
              <LanguageSelector
                onClick={() => {
                  changeLanguage("en-US");
                }}
                className={`${selectedLanguage === "en-US" && "selected"}`}
              >
                <Translator path="home.languages.en" />
              </LanguageSelector>
              <LanguageSelector
                onClick={() => {
                  changeLanguage("es");
                }}
                className={`${selectedLanguage === "es" && "selected"}`}
              >
                <Translator path="home.languages.es" />
              </LanguageSelector>
            </Tooltip>
          </Menu>
        </Spacer>
      </Page>
    </Holder>
  );
}
