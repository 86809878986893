import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Question from "../shared/question";

const Holder = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: start;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 32px;
  &.last {
    margin-bottom: 0px;
  }

  @media (max-width: 768px) {
    display: block;
  }
`;

const Icon = styled.div`
  width: 190px;
  height: 160px;
  background-color: #4f5867;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
    height: 160px !important;
  }
`;
const Image = styled.img`
  width: 112px;
`;

export default function Boolean({
  cNumber,
  number,
  flag,
  title,
  checked,
  tooltip,
}) {
  const [height, setHeight] = useState();

  function handleBoxSize() {
    try {
      setHeight(
        document.getElementById(`${flag}_${number}_boolean`).offsetHeight
      );
    } catch (error) {
      // console.log(error);
    }
    // if (window.innerWidth > 1024) {
    //   setHeight(160);
    // }
  }

  useEffect(() => {
    window.addEventListener("resize", handleBoxSize);
    window.addEventListener("load", handleBoxSize);
    handleBoxSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    checked && (
      <Holder className="boolean" id={`${flag}_${number}_boolean`}>
        <Question
          flag={flag}
          cNumber={cNumber}
          number={number}
          title={title}
          tooltip={tooltip}
          full
        >
          <Icon style={{ height: height }}>
            {checked === "yes" ? (
              <Image src="/img/questions/yes.svg" />
            ) : (
              <Image src="/img/questions/no.svg" />
            )}
          </Icon>
        </Question>
      </Holder>
    )
  );
}
