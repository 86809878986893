export default function Counter(start) {
  let counter = start;
  const getNext = (advance) => {
    if (advance) {
      counter = counter + 1;
    }
    return counter;
  };
  return {
    getNext,
  };
}
