import React, { useState, useEffect } from "react";
import styled from "styled-components";

import WhiteBox from "../white_box";

import Translator from "../../libs/translator";

const Holder = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Phrase = styled.div`
  color: #404751;
  font-family: "Tiempos Headline";
  font-size: 32px;
  line-height: 125%;

  strong {
    font-size: 32px;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    font-size: 24px;
    padding: 32px 0px;

    strong {
      font-size: 24px;
    }
  }
`;

const Numbers = styled.div`
  margin-right: 32px;
  width: 32%;
  height: 654px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(/img/home/poor_data.svg);
  border-radius: 12px;
  overflow: hidden;

  display: flex;
  align-items: start;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 32px;
    display: none;
  }
`;

export default function PoorData() {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    window.addEventListener("load", handleWindowSizeChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Holder>
      <Numbers />
      <WhiteBox
        paddingLeftRight={width < 768 ? 24 : 40}
        paddingUpBottom={width < 768 ? 24 : 40}
        radius={12}
        height={width < 768 ? "auto" : 654}
        width={width < 768 ? "100%" : "67%"}
      >
        <Phrase>
          <Translator path="home.poorData" />
        </Phrase>
      </WhiteBox>
    </Holder>
  );
}
