import React from "react";
import styled from "styled-components";

const Holder = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  @media (max-width: 768px) {
  }
`;

export default function WhiteBox({
  children,
  paddingUpBottom,
  paddingLeftRight,
  marginRight,
  marginLeft,
  radius,
  width,
  height,
  backgroundColor,
}) {
  return (
    <Holder
      style={{
        padding: `${paddingUpBottom}px ${paddingLeftRight}px`,
        borderRadius: radius,
        width: width ? width : "100%",
        height: height ? height : "auto",
        marginRight: marginRight ? marginRight : 0,
        marginLeft: marginLeft ? marginLeft : 0,
        backgroundColor: backgroundColor ? backgroundColor : "#fff",
      }}
    >
      {children}
    </Holder>
  );
}
