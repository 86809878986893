import React from "react";
import styled from "styled-components";

import translatorText from "../../libs/translator_text";

import Country from "./shared/country";

const Holder = styled.div`
  @media (max-width: 768px) {
  }
`;

export default function Paraguai() {
  return (
    <Holder>
      <Country
        number={"05"}
        name={translatorText("countries.paraguai.name")}
        flag={"paraguai"}
        code={"PRY"}
        description={"countries.paraguai.description"}
      />
    </Holder>
  );
}
