import React from "react";
import styled from "styled-components";

import { Tooltip } from "react-tooltip";

const Holder = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    display: block;
  }
`;

const ContentHolder = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 60%;

  &.full {
    width: 80%;
  }

  @media (max-width: 1024px) {
    display: block;
    width: 100%;
  }
`;

const Number = styled.div`
  padding: 40px;
  text-align: center;
  color: #404751;
  font-size: 32px;
  font-weight: 700;
  line-height: 84%;
  letter-spacing: -1.6px;
  text-transform: uppercase;

  @media (max-width: 1024px) {
    padding: 24px;
    text-align: left;
  }
`;

const Title = styled.div`
  width: 100%;
  padding: 32px 0px;
  text-align: left;
  color: #404751;
  font-family: "Tiempos Headline";
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;

  strong {
    font-size: 32px;
    font-weight: bold;
    font-family: "Tiempos Headline";
  }

  @media (max-width: 1024px) {
    padding: 0px 24px 24px 24px;
    font-size: 20px;
    strong {
      font-size: 20px;
    }
  }
`;

const Children = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  align-items: start;
  justify-content: end;

  @media (max-width: 768px) {
    display: block;
    width: 100%;
  }
`;

const PlusSign = styled.img`
  cursor: pointer;
  transform: translateY(4px);
  margin-left: 3px;
  margin-right: 3px;
  width: 30px;
  height: 30px;
`;

export default function Question({
  cNumber,
  number,
  flag,
  title,
  children,
  full,
  tooltip,
}) {
  return (
    <Holder>
      <ContentHolder className={`${full && "full"}`}>
        <Number>
          {`${parseInt(cNumber)}.`}
          {number}
        </Number>
        <Title>
          {title}{" "}
          {tooltip && (
            <PlusSign
              src="/img/home/tooltip.svg"
              data-tooltip-id={`${flag}_${number}_tooltip`}
            />
          )}
        </Title>
        {tooltip && (
          <Tooltip
            id={`${flag}_${number}_tooltip`}
            place="top"
            className="tooltip"
            clickable
          >
            {tooltip}
          </Tooltip>
        )}
      </ContentHolder>
      <Children>{children}</Children>
    </Holder>
  );
}
