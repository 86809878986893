import React from "react";
import styled from "styled-components";

import Translator from "../../libs/translator";
import TranslatorText from "../../libs/translator_text";
import Spacer from "../spacer";

import Flag from "./flag";

const Holder = styled.div`
  color: #000;

  font-family: "Tiempos Headline";
  font-size: 40px;
  line-height: 150%;

  strong {
    font-size: 40px;
    font-weight: bold;
    color: #000;
    font-family: "Tiempos Headline";
  }

  @media (max-width: 768px) {
    font-size: 24px;

    strong {
      font-size: 24px;
    }
  }
`;

const FlagHolder = styled.div`
  float: left;
  width: 33%;
  height: 140px;
  margin-bottom: 16px;
  padding: 0px 8px 0px 0px;

  &.center {
    padding: 0px 8px;
  }

  &.right {
    padding: 0px 0px 0px 8px;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0px !important;
    margin-bottom: 16px;
    height: 112px;

    &.empty {
      display: none;
    }
  }
`;

const Clear = styled.div`
  clear: both;
`;

const Source = styled.div`
  color: #404751;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%; /* 20px */
  padding-top: 16px;

  @media (max-width: 768px) {
    padding-top: 16px;
  }
`;

export default function Flags() {
  // const [width, setWidth] = useState(window.innerWidth);

  // function handleWindowSizeChange() {
  //   setWidth(window.innerWidth);
  // }

  // useEffect(() => {
  //   window.addEventListener("resize", handleWindowSizeChange);
  //   window.addEventListener("load", handleWindowSizeChange);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <Holder>
      <Translator path="home.flags.title" />
      <Source>
        <Translator path="home.flags.source" />
      </Source>
      <Spacer height={40} />
      <div id="flags_menu" />
      <FlagHolder className="empty">
        <Flag empty />
      </FlagHolder>
      <FlagHolder className="empty">
        <Flag empty />
      </FlagHolder>
      <FlagHolder className="right">
        <Flag
          number={"01"}
          country={TranslatorText("home.flags.country1")}
          flag="argentina"
          flagHover={"argentina_hover"}
          link={"argentina_holder"}
        />
      </FlagHolder>
      <Clear />
      <FlagHolder>
        <Flag
          number={"02"}
          country={TranslatorText("home.flags.country2")}
          flag="brasil"
          flagHover={"brasil_hover"}
          link={"brasil_holder"}
        />
      </FlagHolder>
      <FlagHolder className="center">
        <Flag
          number={"03"}
          country={TranslatorText("home.flags.country3")}
          flag="colombia"
          flagHover={"colombia_hover"}
          link={"colombia_holder"}
        />
      </FlagHolder>
      <FlagHolder className="right">
        <Flag
          number={"04"}
          country={TranslatorText("home.flags.country4")}
          flag="guatemala"
          flagHover={"guatemala_hover"}
          link={"guatemala_holder"}
        />
      </FlagHolder>
      <Clear />
      <FlagHolder>
        <Flag
          number={"05"}
          country={TranslatorText("home.flags.country5")}
          flag="paraguai"
          flagHover={"paraguai_hover"}
          link={"paraguai_holder"}
        />
      </FlagHolder>
      <FlagHolder className="center">
        <Flag
          number={"06"}
          country={TranslatorText("home.flags.country6")}
          flag="reinounido"
          flagHover={"reinounido_hover"}
          link={"reinounido_holder"}
        />
      </FlagHolder>
      <FlagHolder className="right">
        <Flag
          number={"07"}
          country={TranslatorText("home.flags.country7")}
          flag="suica"
          flagHover={"suica_hover"}
          link={"suica_holder"}
        />
      </FlagHolder>
      <Clear />
    </Holder>
  );
}
