import React from "react";
import styled from "styled-components";

import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import Translator from "../../libs/translator";
import TranslatorText from "../../libs/translator_text";

import Spacer from "../../components/spacer";

import { Tooltip } from "react-tooltip";

const Holder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Box = styled.div`
  width: 66%;
  border-radius: 12px;
  // overflow: hidden;
  background-color: #fff;
  height: 460px;

  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;

  &.center {
    margin-left: 32px;
    margin-right: 32px;
  }

  &.last {
    width: 33%;
    margin-left: 32px;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 32px;
    height: 460px;

    &.last {
      margin-bottom: 0px;
      width: 100%;
      margin-left: 0px;
    }

    &.center {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
`;

const Source = styled.div`
  color: #404751;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%; /* 20px */
  padding-top: 16px;

  @media (max-width: 768px) {
    padding-top: 16px;
  }
`;

const Label = styled.div`
  padding: 40px;
  color: #404751;
  font-family: "Tiempos Headline";
  font-size: 32px;
  line-height: 125%;
  width: 100%;

  strong {
    font-weight: bold;
    font-family: "Tiempos Headline";
    font-size: 32px;
  }

  @media (max-width: 768px) {
    padding: 24px;
  }
`;

const PlusSign = styled.img`
  cursor: pointer;
  transform: translateY(3px);
  margin-left: 3px;
  margin-right: 3px;
`;

// const ProgressHolder = styled.div`
//   padding: 0px 40px 40px;
//   height: 250px;
//   display: flex;
//   align-items: end;
//   justify-content: space-between;

//   @media (max-width: 768px) {
//     padding: 24px;
//   }
// `;

// const Progress = styled.div`
//   position: relative;
//   background-color: #fff;
//   width: 100%;
//   height: 100%;
//   /* overflow: hidden; */
//   margin-top: 8px;

//   display: flex;
//   align-items: end;
//   justify-content: space-between;
// `;

// const Bar = styled.div`
//   height: 100%;
//   width: 70%;
//   background-color: #ef642e;
//   border-radius: 4px;

//   strong {
//     font-weight: bold;
//     font-size: 16px;
//     color: #fff;
//     margin-left: 8px;
//   }
// `;

// const BarsLabel = styled.div`
//   position: absolute;
//   bottom: 30px;
//   left: 40px;
//   right: 45px;
//   color: #404751;
//   font-family: Moderat;
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 700;
//   line-height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;

//   span {
//     display: block;

//     &.spacer {
//       width: 100%;
//     }
//   }

//   @media (max-width: 768px) {
//     left: 24px;
//     right: 29px;
//     bottom: 40px;
//   }
// `;

export default function Charts({ width }) {
  Chart.register(CategoryScale);

  // const data = [
  //   parseInt(TranslatorText("home.charts.bars.2009")),
  //   parseInt(TranslatorText("home.charts.bars.2010")),
  //   parseInt(TranslatorText("home.charts.bars.2011")),
  //   parseInt(TranslatorText("home.charts.bars.2012")),
  //   parseInt(TranslatorText("home.charts.bars.2013")),
  //   parseInt(TranslatorText("home.charts.bars.2014")),
  //   parseInt(TranslatorText("home.charts.bars.2015")),
  //   parseInt(TranslatorText("home.charts.bars.2016")),
  //   parseInt(TranslatorText("home.charts.bars.2017")),
  //   parseInt(TranslatorText("home.charts.bars.2018")),
  //   parseInt(TranslatorText("home.charts.bars.2019")),
  //   parseInt(TranslatorText("home.charts.bars.2020")),
  //   parseInt(TranslatorText("home.charts.bars.2021")),
  //   parseInt(TranslatorText("home.charts.bars.2022")),
  // ];

  // const data_labels = [
  //   TranslatorText("home.charts.bars_labels.2009"),
  //   TranslatorText("home.charts.bars_labels.2010"),
  //   TranslatorText("home.charts.bars_labels.2011"),
  //   TranslatorText("home.charts.bars_labels.2012"),
  //   TranslatorText("home.charts.bars_labels.2013"),
  //   TranslatorText("home.charts.bars_labels.2014"),
  //   TranslatorText("home.charts.bars_labels.2015"),
  //   TranslatorText("home.charts.bars_labels.2016"),
  //   TranslatorText("home.charts.bars_labels.2017"),
  //   TranslatorText("home.charts.bars_labels.2018"),
  //   TranslatorText("home.charts.bars_labels.2019"),
  //   TranslatorText("home.charts.bars_labels.2020"),
  //   TranslatorText("home.charts.bars_labels.2021"),
  //   TranslatorText("home.charts.bars_labels.2022"),
  // ];

  // const maxData = Math.max(...data);

  const state = {
    labels: [
      `${TranslatorText("home.charts.pie.data1")} (%)`,
      `${TranslatorText("home.charts.pie.data2")} (%)`,
      `${TranslatorText("home.charts.pie.data3")} (%)`,
      `${TranslatorText("home.charts.pie.data4")} (%)`,
    ],
    datasets: [
      {
        backgroundColor: ["#EF642E", "#6A6E53", "#4F5867", "#E0EBE3"],
        hoverBackgroundColor: ["#EF642E", "#6A6E53", "#4F5867", "#E0EBE3"],
        data: [94, 5, 1, 0],
      },
    ],
  };

  return (
    <>
      <Holder>
        <Box style={{ backgroundImage: "url(/img/home/pills.svg)" }}>
          <Label>
            <Translator path="home.charts.pills" />
            <PlusSign src="/img/home/tooltip.svg" data-tooltip-id="pills" />
          </Label>
          <Tooltip id="pills" place="bottom" className="tooltip">
            <Translator path="home.charts.tooltipPills" />
          </Tooltip>
        </Box>
        <Box className="last">
          <Label>
            <Translator path="home.charts.distribuition" />
            <PlusSign
              src="/img/home/tooltip.svg"
              data-tooltip-id="distribuition"
            />
            <Spacer height={32} />
            <div style={{ width: "100%" }}>
              <Doughnut
                data={state}
                height={300}
                options={{
                  maintainAspectRatio: false,
                  responsive: true,
                  cutout: 20,
                  radius: 100,
                  plugins: {
                    tooltip: {
                      enabled: true,
                    },
                    legend: {
                      display: true,
                      position: "top",
                      labels: {
                        boxWidth: 10,
                        boxHeight: 10,
                        useBorderRadius: true,
                        borderRadius: 20,
                        color: "rgba(64, 71, 81, 1)",
                        font: "Moderat",
                      },
                    },
                  },
                }}
              />
            </div>
            <Tooltip id="distribuition" place="bottom" className="tooltip">
              <Translator path="home.charts.tooltipDistribuition" />
            </Tooltip>
          </Label>
        </Box>
        {/* <Box className="last">
          <Label>
            <Translator path="home.charts.countries" />
            <PlusSign src="/img/home/tooltip.svg" data-tooltip-id="bars" />
          </Label>
          <Tooltip id="bars" place="bottom" className="tooltip">
            <Translator path="home.charts.tooltipCharts" />
          </Tooltip>
          <ProgressHolder>
            {data.map((year, i) => {
              return (
                <Progress>
                  <Bar
                    style={{ height: `${(year / maxData) * 100}%` }}
                    data-tooltip-id={`tooltip_charts_bar${year}`}
                  />
                  <Tooltip
                    id={`tooltip_charts_bar${year}`}
                    place="top"
                    className="tooltip"
                  >
                    {data_labels[i]}
                  </Tooltip>
                </Progress>
              );
            })}
            <BarsLabel>
              <span>2009</span>
              <span className="spacer"></span>
              <span>2022</span>
            </BarsLabel>
          </ProgressHolder>
        </Box> */}
      </Holder>
      <Source>
        <Translator path="home.charts.source" />
      </Source>
    </>
  );
}
