import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const TranslatorText = (path, x, y, z) => {
  const { t } = useTranslation();

  let _t = t(path);

  if (_t.length < 1) {
    return null;
  }

  if (x || x === 0) {
    _t = _t.replace("[x]", x);
  }

  if (y || y === 0) {
    _t = _t.replace("[y]", y);
  }

  if (z || z === 0) {
    _t = _t.replace("[z]", z);
  } else {
    _t = _t.replace("[z]", 15);
  }

  return parse(_t);
};

export default TranslatorText;
