import React from "react";
import styled from "styled-components";

import { Tooltip } from "react-tooltip";

import Question from "../shared/question";

const Holder = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 32px;
  &.last {
    margin-bottom: 0px;
  }

  @media (max-width: 768px) {
    display: block;
    height: auto;
  }
`;

const Chart = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: start;
  justify-content: end;
  padding: 40px;

  @media (max-width: 768px) {
    padding: 120px 24px 24px;
    justify-content: center;
  }
`;

const ChartHolder = styled.div`
  width: 380px;
  height: 380px;
  position: relative;

  @media (max-width: 768px) {
    width: 250px;
    height: 250px;
    /* transform: translateX(40px); */
  }
`;

const ChartTop = styled.div`
  position: absolute;
  right: 380px;
  margin-right: 16px;
  top: 30px;
  color: #404751;

  text-align: right;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: -1px;

  strong {
    color: #404751;
    font-size: 20px;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    /* position: relative; */
    left: 0px;
    text-align: left;
    right: auto;
    bottom: 266px;
    top: auto;
    width: 45%;
    font-size: 16px;
    strong {
      font-size: 16px;
    }
  }
`;

const ChartBottom = styled.div`
  position: absolute;
  right: 380px;
  margin-right: 16px;
  bottom: 20px;
  color: #f15922;

  text-align: right;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: -1px;

  strong {
    color: #f15922;
    font-size: 20px;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    right: auto;
    left: 50%;
    top: auto;
    bottom: 266px;
    font-size: 16px;
    strong {
      font-size: 16px;
    }
  }
`;

const ChartFull = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: #cfe0d3;
`;

const ChartProgress = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background-color: #ef642e;
`;

const ChartMask = styled.img`
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 4;

  width: 100%;
  height: 100%;
`;

export default function Ball({
  cNumber,
  number,
  title,
  topValue,
  topUnit,
  topLabel,
  bottomValue,
  bottomUnit,
  bottomLabel,
  manLabel,
  womanLabel,
  othersLabel,
  man,
  woman,
  others,
  tooltip,
  flag,
  extraTooltip,
}) {
  return topValue && bottomValue ? (
    <Holder className="ball">
      <Question
        cNumber={cNumber}
        number={number}
        title={title}
        tooltip={extraTooltip}
      >
        <Chart>
          <ChartHolder>
            <ChartFull />
            <ChartProgress
              style={{ height: `${(bottomValue / topValue) * 100}%` }}
            />
            <ChartMask
              src="/img/charts/mask_ball.svg"
              data-tooltip-id={
                (woman || man || others || tooltip) && `${flag}_${number}_data`
              }
            />
            <ChartTop>
              <strong>
                {topValue < 1000 ? topValue : (topValue / 1000).toFixed(0)}{" "}
                {topValue > 999 && topUnit}
              </strong>
              <br /> {topLabel}
            </ChartTop>
            <ChartBottom>
              <strong>
                {bottomValue < 1000
                  ? bottomValue
                  : (bottomValue / 1000).toFixed(0)}{" "}
                {bottomValue > 999 && bottomUnit}
              </strong>
              <br /> {bottomLabel}
            </ChartBottom>
          </ChartHolder>
        </Chart>
        <Tooltip id={`${flag}_${number}_data`} place="left" className="tooltip">
          {woman ? (
            <>
              {womanLabel}: {woman}
              <br />
            </>
          ) : null}

          {man ? (
            <>
              {manLabel}: {man}
              <br />
            </>
          ) : null}

          {others ? (
            <>
              {othersLabel}: {others}
              <br />
            </>
          ) : null}
          {tooltip ? <>{tooltip}</> : null}
        </Tooltip>
      </Question>
    </Holder>
  ) : null;
}
