import React, { useState } from "react";
import styled from "styled-components";

import Translator from "../../libs/translator";

const Holder = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Title = styled.div`
  width: 100%;
  color: #404751;
  font-size: 54px;
  font-weight: 700;
  line-height: 90%; /* 54px */
  letter-spacing: -2.7px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    color: #404751;
    font-size: 37px;
    font-style: normal;
    font-weight: 700;
    line-height: 84%; /* 31.08px */
    letter-spacing: -1.85px;
    text-transform: uppercase;
    padding-bottom: 32px;
  }
`;

const Line = styled.img`
  transform: translateY(-20px);

  @media (max-width: 768px) {
    transform: translateY(-10px);
  }
`;

const Description = styled.div`
  width: 100%;
  color: #404751;
  font-size: 20px;
  line-height: 150%;
  height: 400px;
  overflow: hidden;
  position: relative;
  transition: 0.5s;

  strong {
    font-weight: bold;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Gradient = styled.div`
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  width: 100%;
  height: 200px;
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: 1;
`;

const Button = styled.div`
  border-radius: 39px;
  background: #404751;
  width: fit-content;
  margin: 0 auto;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0px;
  z-index: 2;
  color: #fff;
  cursor: pointer;
  left: 50%;
  transform: translateX(-50%);
`;

const Arrow = styled.img`
  margin-left: 8px;
`;

export default function Content() {
  const [open, setOpen] = useState(false);

  return (
    <Holder>
      <Title>
        <Translator path="about.content.name" />{" "}
        <Line src="/img/home/line_title.svg" />
      </Title>
      <Description style={{ height: open ? "auto" : 400 }}>
        <Translator path="about.content.text" />
        {!open && <Gradient />}
        {!open && (
          <Button
            onClick={() => {
              setOpen(true);
            }}
          >
            <Translator path="general.readMore" />
            <Arrow src="/img/about/arrow_down.svg" />
          </Button>
        )}
      </Description>
    </Holder>
  );
}
