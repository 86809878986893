import React from "react";
import styled from "styled-components";

import translatorText from "../../libs/translator_text";

import Country from "./shared/country";

const Holder = styled.div`
  @media (max-width: 768px) {
  }
`;

export default function Brasil() {
  return (
    <Holder>
      <Country
        number={"02"}
        name={translatorText("countries.brasil.name")}
        flag={"brasil"}
        code={"BRA"}
        description={"countries.brasil.description"}
        hidePercentage={true}
      />
    </Holder>
  );
}
