import React from "react";
import styled from "styled-components";

const Holder = styled.div`
  margin: 0 auto;
  max-width: 900px;

  color: #404751;

  text-align: center;
  font-family: "Tiempos Headline";
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 30px */

  strong {
    color: #404751;
    font-family: "Tiempos Headline";
    font-size: 40px;
    font-style: normal;
    font-weight: bold;
    line-height: 125%;
  }

  @media (max-width: 768px) {
    font-size: 24px;
    strong {
      font-size: 24px;
    }
  }
`;

export default function Phrase({ children }) {
  return <Holder>{children}</Holder>;
}
