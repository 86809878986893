import React from "react";
import styled from "styled-components";

import { Tooltip } from "react-tooltip";
import Translator from "../../libs/translator";

const Holder = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Title = styled.div`
  width: 100%;
  color: #ef642e;
  font-size: 72px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -3.6px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    color: #ef642e;
    font-size: 37px;
    font-style: normal;
    font-weight: 700;
    line-height: 84%; /* 31.08px */
    letter-spacing: -1.85px;
    text-transform: uppercase;
    padding-bottom: 32px;
  }
`;

const Line = styled.img`
  transform: translateY(-20px);

  @media (max-width: 768px) {
    transform: translateY(-10px);
  }
`;

const Description = styled.div`
  width: 67%;
  color: #404751;
  font-size: 20px;
  line-height: 150%;

  strong {
    font-weight: bold;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const PlusSign = styled.img`
  cursor: pointer;
  transform: translateY(3px);
  margin-left: 3px;
  margin-right: 3px;
`;

export default function Intro() {
  return (
    <Holder>
      <Title>
        <Translator path="home.intro.name" />{" "}
        <Line src="/img/home/line_title.svg" />
      </Title>
      <Description>
        <Translator path="home.intro.text1" /> <br />
        <Translator path="home.intro.text2" />
        <PlusSign src="/img/home/tooltip.svg" data-tooltip-id="chagas" />
        <Translator path="home.intro.text3" />
        <PlusSign src="/img/home/tooltip.svg" data-tooltip-id="dndi" />
        <Translator path="home.intro.text4" />
        <PlusSign src="/img/home/tooltip.svg" data-tooltip-id="world" />
      </Description>
      <Tooltip id="chagas" place="bottom" className="tooltip" clickable>
        <Translator path="home.intro.tooltipChagas" />
      </Tooltip>
      <Tooltip id="dndi" place="bottom" className="tooltip" clickable>
        <Translator path="home.intro.tooltipDndi" />
      </Tooltip>
      <Tooltip id="world" place="bottom" className="tooltip" clickable>
        <Translator path="home.intro.tooltipWorld" />
      </Tooltip>
    </Holder>
  );
}
