import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Translator from "../../libs/translator";

import Video from "../video";

const Holder = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  align-items: start;
  justify-content: space-between;

  @media (max-width: 768px) {
    display: block;
    height: auto;
  }
`;

const Data = styled.div`
  width: 60%;
  height: 400px;
  padding: 20px 48px 56px 48px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;

  @media (max-width: 768px) {
    display: block;
    height: auto;
    width: 100%;
    padding: 20px 24px;
  }
`;

const Quotes = styled.div`
  color: #404751;
  font-family: "Tiempos Headline";
  font-size: 168px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 210px */
  overflow: hidden;
  @media (max-width: 768px) {
    height: 80px;
  }
`;
const Title = styled.div`
  color: #404751;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  @media (max-width: 768px) {
    margin-bottom: 32px;
  }
`;

const Space = styled.div`
  height: 100%;
`;

const Name = styled.div`
  color: #404751;
  font-family: "Tiempos Headline";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%; /* 25px */
`;
const Label = styled.div`
  color: #404751;
  font-family: "Tiempos Headline";
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
`;

export default function VideoTestimonial({
  title,
  name,
  label,
  width,
  height,
  cover,
}) {
  const [_width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    window.addEventListener("load", handleWindowSizeChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Holder>
      <Video
        width={_width < 768 ? "100%" : width}
        height={_width < 768 ? 300 : height}
        cover={cover}
      />
      <Data>
        <Quotes>“</Quotes>
        <Title>
          <Translator path={title} />
        </Title>
        <Space />
        <Name>
          <Translator path={name} />
        </Name>
        <Label>
          <Translator path={label} />
        </Label>
      </Data>
    </Holder>
  );
}
