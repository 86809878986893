import React from "react";
import styled from "styled-components";

import Translator from "../libs/translator";
import Spacer from "./spacer";

const Holder = styled.div`
  padding: 0px;

  @media (max-width: 768px) {
    padding: 32px;
    display: block;
  }
`;

const FooterHolder = styled.div`
  padding: 64px;
  background-color: #727c51;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    padding: 32px;
    display: block;
  }
`;

const Text = styled.div`
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  line-height: 150%;
  width: 100%;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const Title = styled.div`
  color: #404751;
  text-align: center;
  font-family: "Tiempos Headline";
  font-size: 54px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  margin-bottom: 32px;
`;

const Box = styled.div`
  margin: 0 auto;
  width: 100%;
  height: 408px;
  max-width: 1440px;
  background-color: #ef642e;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  align-items: start;
  justify-content: space-between;

  @media (max-width: 768px) {
    /* padding: 32px; */
    display: block;
    height: auto;
    position: relative;
    /* right: 16px;
    left: 16px; */
  }
`;

const ImageBox = styled.img`
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ContentBox = styled.div`
  width: 100%;
  height: 408px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  padding: 40px;
`;

const HelpText = styled.div`
  color: #fff;

  font-size: 24px;
  font-weight: 500;
  line-height: 150%; /* 36px */

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const DataHolder = styled.div`
  width: 100%;
`;

const Contact = styled.div`
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  line-height: 150%; /* 36px */
  padding-bottom: 24px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const Email = styled.a`
  display: block;
  text-align: center;
  padding: 18px 0px;
  width: 100%;
  border-radius: 106px;
  background: #727c51;
  color: #fff;

  font-size: 24px;
  font-weight: 700;
  line-height: 150%; /* 36px */

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    padding: 18px 0px;
    font-size: 14px;
  }
`;

const ImageHolder = styled.div`
  width: 100%;
  text-align: center;

  @media (max-width: 768px) {
    margin-top: 32px;
  }
`;

const Image = styled.img`
  height: 50px;
`;

export default function Footer() {
  return (
    <>
      <Holder>
        <Title>
          <Translator path="footer.title" />
        </Title>
        <Box>
          <ImageBox src="/img/footer/symbols.svg" />
          <ContentBox>
            <HelpText>
              <Translator path="footer.helpText" />
            </HelpText>
            <DataHolder>
              <Contact>
                <Translator path="footer.contact" />
              </Contact>
              <Email href="mailto:chagas@coalicionchagas.com">
                chagas@coalicionchagas.com
              </Email>
            </DataHolder>
          </ContentBox>
        </Box>
      </Holder>
      <Spacer height={64} />
      <FooterHolder>
        <Text>
          <Translator path="footer.text" />
        </Text>
        <ImageHolder>
          <a href="https://www.coalicionchagas.org/">
            <Image src="/img/about/logo_coalition_chagas.png" />
          </a>
        </ImageHolder>
        <ImageHolder>
          <a href="https://dndial.org/">
            <Image src="/img/about/logo_dndi.png" />
          </a>
        </ImageHolder>
        <ImageHolder>
          <a href="https://world-heart-federation.org/ ">
            <Image src="/img/about/logo_world.png" />
          </a>
        </ImageHolder>
      </FooterHolder>
    </>
  );
}
