const data = {
    translations: {
        language: `English`,
        home: {
            languages: {
                pt: `Português`,
                en: `English`,
                es: `Español`,
            },
            logo: `Chagas Observatory`,
            about: `About`,
            aboutObservatory: `About the Observatory`,
            intro: {
                name: `Chagas Observatory`,
                text1: `Forum for public consultation, communication, and advocacy with information about <strong>Chagas Disease</strong>. Aims to answer the questions: where and how are we? How many are we? And what are we doing? Based on these age-old questions posed by civil society, it seeks to stimulate dialogue and engage different actors in the creation of policies to combat the disease.`,
                text2: `A <strong>Global Chagas Coalition</strong>`,
                text3: `project. Supported by <strong>DNDi</strong>`,
                text4: `and the <strong>World Heart Federation</strong>`,
                tooltipChagas: `The <a href="https://www.coalicionchagas.org/en/" target="_blank">Global Chagas Coalition</a> is a collaborative alliance for the exchange of experiences, knowledge, and capacity for action to increase visibility of this problem and commitment to alleviate the human suffering it causes, with the aim of eliminating it as a public health issue.`,
                tooltipDndi: `The <a href="https://dndial.org/" target="_blank">Drugs for Neglected Diseases initiative (DNDi)</a> is an international non-profit research organization that develops treatments and access projects for the most vulnerable populations, especially those affected by socially determined diseases.`,
                tooltipWorld: `Leading and organizing global <a href="https://world-heart-federation.org/what-is-cvd/" target="_blank">cardiovascular health</a>, the World Heart Federation advocates for heart health and works to reduce the global burden of heart diseases, including Chagas Disease. The Federation is composed of over 200 heart foundations, scientific societies, and patient organizations in more than 100 countries.`,
            },
            phrase1: `What if you could help eliminate a disease that is invisible but real for many people?`,
            phrase2: `The sooner the person discovers the illness, the better, because if treated in the acute phase it is <strong>almost always curable</strong>.`,
            phrase3: `These are the two forms of treatment - benznidazole and nifurtimox - and their distribution worldwide.`,
            testimonial: {
                text: `For 10 years, I underwent serological tests, and only in 2020 I finally tested negative for Chagas Disease. You can also test negative especially if you get the treatment early, you can also heal.`,
                name: `Idalia Isabel Mercado Hernández`,
                label: `32 years old, Mexico`,
            },
            map: {
                text1: `We can contribute to raising awareness and to controlling Chagas disease, a health problem that affects approximately <strong>7 million people</strong> worldwide.`,
                source1: `Source: WHO - World Health Organization.`,
                text2: `of this population doesn't know they have the disease so diagnosing and treating at the primary care level is crucial.`,
                source2: `Source: Pan American Health Organization.`,
            },
            charts: {
                pills: `<strong>5 million</strong> medications distributed, equivalent to over 21,000 treatments provided between 2021 and 2022`,
                tooltipPills: `It considers 100mg and 50mg tablets for benznidazole Abarax produced by ELEA; 100mg and 12.5mg tablets for benznidazole produced by LAFEPE; and 120mg tablets for Nifurtimox produced by Bayer. Currently, these are the only medicines and producers for Chagas disease worldwide. Medication distribution data corresponds to an approximate total of 21,007 treatments performed. Approximate calculation: 200 tablets for 1 treatment with benznidazole and 300 tablets for 1 treatment with nifurtimox.`,
                distribuition: `Distribution and treatment regions`,
                tooltipDistribuition: `Data refers to the years 2021 and 2022.`,
                tooltipCharts: `Data from 2009 to 2022. All tablets correspond to a dosage of 120mg (adult).`,
                countries: `Distribution of <strong>Nifurtimox</strong> from 2009 to 2022`,
                source: `Source: ELEA, Exeltis, FMS España, FMS Argentina, WHO, Bayer, and LAFEPE`,
                pie: {
                    data1: `Latin America`,
                    data2: `Europe`,
                    data3: `North America`,
                    data4: `Africa, Asia, and Oceania`,
                },
                bars: {
                    2009: "270000",
                    2010: "467200",
                    2011: "464000",
                    2012: "466400",
                    2013: "268000",
                    2014: "1029200",
                    2015: "948600",
                    2016: "1212000",
                    2017: "1023500",
                    2018: "1063600",
                    2019: "1179200",
                    2020: "529600",
                    2021: "1529000",
                    2022: "951700",
                },
                bars_labels: {
                    2009: "270.000",
                    2010: "467.200",
                    2011: "464.000",
                    2012: "466.400",
                    2013: "268.000",
                    2014: "1.029.200",
                    2015: "948.600",
                    2016: "1.212.000",
                    2017: "1.023.500",
                    2018: "1.063.600",
                    2019: "1.179.200",
                    2020: "529.600",
                    2021: "1.529.000",
                    2022: "951.700",
                },
            },
            years: `Despite Chagas disease having been discovered over 100 years ago, there is still much to be done to eliminate it as a public health problem.`,
            poorData: `This is partly because it is traditionally associated with poorer populations living in remote and rural areas, and partly due to lack of awareness and political and financial commitment.`,
            slideshow: {
                1: `Recife, Brazil. 2023. Xavier Vahed-DNDi`,
                2: `Recife, Brazil. 2023. Xavier Vahed-DNDi`,
                3: `Recife, Brazil. 2023. Xavier Vahed-DNDi`,
                4: `Jutiapa, Guatemala. 2023. Dan Ramírez Guerrero-DNDi`,
                5: `Jutiapa, Guatemala. 2023. Dan Ramírez Guerrero-DNDi`,
                6: `Jutiapa, Guatemala. 2023. Dan Ramírez Guerrero-DNDi`,
                7: `Santander, Colombia. 2019. Ana Ferreira-DNDi`,
                8: `Argentina. 2015. Bayer`,
                9: `Argentina. 2015. Bayer`,
                10: `Mendoza, Argentina. 2015. Bayer`,
                11: `Mendoza, Argentina. 2015. Bayer`,
                12: `Mendoza, Argentina. 2015. Bayer`,
            },
            slideshowAlt: {
                1: `woman sitting on the bank watching a child jump`,
                2: `woman looking at the medication blister packs on the table`,
                3: `woman lying in the background with electrodes in the foreground`,
                4: `family, mother standing and two children sitting`,
                5: `medical consultation, man lying down`,
                6: `woman in blue sitting at a medical consultation`,
                7: `man in line`,
                8: `In Argentina`,
                9: `In Argentina`,
                10: `In Argentina`,
                11: `In Argentina`,
                12: `In Argentina`,
            },
            flags: {
                title: `<strong>The Observatory</strong> is a result of to increase awareness of Chagas Disease around the world as well as make existing information available to all stakeholders. It's important to remember that there is immense <strong>underreporting</strong> of cases of the disease worldwide, since less than 10% of people with Chagas infection receive timely diagnosis and treatment.`,
                source: `Source: Pan American Health Organization.`,
                country1: `Argentina`,
                country2: `Brazil`,
                country3: `Colombia`,
                country4: `Guatemala`,
                country5: `Paraguay`,
                country6: `UK/London`,
                country7: `CH/Geneva`,
            },
        },
        about: {
            intro: {
                name: `About <br /> Chagas <br /> Observatory`,
                text: `<strong>Some footsteps are heard</strong><br /><br />
        They are moving forward. Some countries are moving forward in short, slow steps, but they are moving forward. They are moving forward in the midst of the epidemiological silence that makes Chagas one of the most invisible diseases.<br /><br />
So far, 115 years after its discovery, the most notable advances have been in the control of vector-borne transmission of this disease, endemic in many regions of the Americas. But this progress has not been matched by progress in the provision of care for people affected by the disease.<br /><br />
Two years ago, people living with Chagas disease made an appeal on World Chagas Day (14 April): "Help us to know how many of us there are and where we are". Because right now, the data we have on Chagas disease are only  estimates. There may be more than 75 million people at risk and its probable  that more than 7 million people are currently infected. <br /><br />
Without clearer and more reliable information, health systems face difficult decisions in allocating resources appropriately. And as a society we don't know the extent of the problem we face both in the endemic regions of the Americas and in the rest of the world, where the disease has spread through migratory movements and other non-vectorial transmission routes, such as mother-to-child transmission.<br /><br />
However, today we have more evidence and experience in managing the disease than in the past. Therefore, we know the immense importance of comprehensive care. This is the call for World Chagas Day 2024: timely diagnosis, treatment and continuity  of care for people living with Chagas disease. Prevent transmission and avoid the harms of infection and death. It is a race amidst the fog, because of  the lack of accurate information. But there are advances  being made.<br /><br />
This observatory looks at some of the progress made by countries in the fight against Chagas disease and is based on data provided by a group of these countries and their health systems, as well as by some partners of the Global Chagas Coalition. The information available focuses on the scale-up of access to care.<br /><br />
This first edition highlights progress made in Brazil, Argentina, Colombia, Guatemala and Paraguay, along with the canton of Geneva in Switzerland and the city of London in the United Kingdom. The geographical diversity of this first sample gives an overview of the global situation.<br /><br />
All the advances identified indicate that the epidemiological silence is gradually being broken. For example, the implementation of a mandatory reporting system for chronic cases in Brazil, the increase in the population diagnosed in high-incidence areas in Colombia, and the advanced policies implemented in Argentina are just some of the signs of progress towards the ultimate goal of eliminating Chagas as a public health problem. At the same time, data on the distribution of the two existing drugs for the disease in the world (Benznidazole and Nifurtimox) bring us closer to the actual number of people that countries have treated.<br /><br />
The creation of the Chagas Observatory responds to the request of those affected to "know how many of us there are and where we are" and to the need to establish dialogue between health systems and civil society. This year, global estimates from the Pan American Health Organization (PAHO) are due to be updated, as well as other initiatives that aim to uncover the hidden reality of data on Chagas disease.<br /><br />
Basic information on the care of affected people in countries is not easy to find. They are often invisible to the health ministries' own surveillance systems because the disease is not prioritized or adequately resourced. The complexity of the disease, asymptomatic in many cases, does not make it any easier either. But there is a need to insist on more accurate and easily accessible information, enabling the planning of health interventions and the allocation of the necessary resources. It is also the basis for successful research into new diagnostic and treatment tools.<br /><br />
Our Observatory is a small first step, with a pilot group of commendable countries with whom we have been in dialogue to create this project. Its aim is simply to provide an overview and snapshot of what is known today about Chagas disease in these places and to encourage others to collect and share their information and data to fight for this cause that belongs to all of us.<br /><br />
We are still far from seeing the true magnitude of the disease in all its dimensions, but this breakthrough comes with the sound of footsteps, amidst the darkness that still surrounds this issue.<br /><br />
        <i>Signed by: Global Chagas Coalition Secretariat (DNDi, Mundo Sano Foundation, ISGlobal, Texas Children's Hospital Centre for Vaccine Development- Baylor College of Medicine, CEADES,).<br /><br />
        The Chagas Progress Observatory is an initiative of the advocacy working group of the Global Chagas Coalition, led by volunteer members of organizations such as DNDi and the World Heart Federation.</i>
        `,
            },
            content: {
                name: `The need for adequate information`,
                text: `<i>By Roberto Chuit, Consultant to the Pan American Health Organization</i><br /><br />
        The lack of registries and/or adequate data for Chagas disease makes it necessary to make prevalence estimates, which present difficulties in their execution, since they are often based on studies of specific areas, with different designs and sample sizes, involving different time scales; sometimes these studies are not representative of a geographic region, since they do not consider the diversity and environmental changes.<br /><br />

The purpose of estimates is to assist in decision making, planning, budgeting, resource management and distribution. It should be taken into consideration that an estimate is a statistical element that covers a wide range of possibilities and that its precision depends on the data sources on which it is based, as well as on the different valuations or biases in the definition of the variables that compose it, and that can therefore modify the final estimated value.<br /><br />

As we have seen, to make accurate estimates we depend on the data sources which, in the case of Chagas disease, present imprecisions in their definition in the registries:<br /><br />

- Registration of acute cases due to vector-borne causes: although in general in countries there is an obligation to report cases, clinical diagnosis is difficult because the acute phase, caused by the bite of the insect, often goes unnoticed due to mild symptoms, common to other infections. The lack of availability of direct parasitological diagnosis at the local level (Strout or micro-strout, or another appropriately validated technique) and/or the lack of consideration by health professionals as a possible cause contributes to underestimation of disease numbers.  <br /><br />

- Registration of cases of perinatal transmission: the difficulty in this case lies in not diagnosing the positive pregnant woman and not testing her newborn. Even in cases where it is known that the pregnant woman is positive, the lack of availability of parasitological diagnostics in the maternity ward for newborns means that these cases are not investigated and therefore underrecorded.<br /><br />

- Registration of those with chronic forms of the disease: only recently have the registries in countries been adjusted to differentiate between chronic or acute cases.<br /><br />

- Mortality registries: when analyzing vital statistics in the countries, cardiac, digestive and other causes are generally not included in the proportion that, according to data from specific studies carried out in the different regions, they should be.<br /><br />

- Lack of centralized, coordination records: in general, vector cases are recorded by vector control programs, acute cases in the health surveillance system, congenital cases in the medical care system and chronic patients in hospital statistics and are not always unified in a single database.<br /><br />

The difficulty in registering and organizing healthcare systems hides the true presence of the disease and confuses case notifications with the epidemiological profile of the infection in a region, that is, the true presence of the disease since, as we have described, notification only reports a fraction of what is happening in an area.<br /><br />

In view of the points above, for estimates to be accurate, it is necessary to develop prevalence studies for each country and region. If it is impossible to carry out an exhaustive study, it should reflect the geographical reality and diversity (environmental and economic changes, control actions, etc.), changes in the risk of transmission due to variations in the presence of the main vector or its replacement by secondary vectors, underreporting of cases (acute vectorial transmission, congenital, oral, etc.) and underreporting of chronic cases.<br /><br />

In summary, we can say that to obtain better information and to know where we are, how we are, and how many people are infected, it is necessary to improve the records, their availability, and correct identification. It is essential that government public health programs be adequate and tailored to each of the local realities.

        `,
            },
        },
        countries: {
            argentina: {
                name: `Argentina`,
                description: `It is estimated that around one and a half million people have Chagas disease in the country, making it one of the main public health problems. Faced with this scenario, Argentina has implemented interventions and awareness programs to mitigate the impact of the disease, as well as sought to improve the quality of care for Chagas patients.`,
                questions: {
                    moreInfo: `InChagas project: An educational intervention to promote early detection and care of chagasic cardiomyopathy.<br /><br />

          The Instituto de Efectividad Clinica y Sanitaria (IECS) is part of the Faculty of Medicine of the University of Buenos Aires and coordinates the InChagas project. The main objective of this project is to improve the dissemination of evidence-based recommendations to improve the detection and quality of care of patients with chagasic cardiomyopathy in an endemic province of Argentina.<br /><br />
          
          The project, which is in its initial implementation phase, will develop and put into practice educational strategies based on tele-education tools, targeting primary care physicians. By improving the training of these doctors, InChagas aims to improve knowledge of the disease at the primary care level, which will result in better detection and interaction between the different levels of care. The project strategy is based on short educational videos, webinars, interactive modules and the dissemination of digital educational materials. All content development and adaptation to local context will be carried out in close collaboration with local Chagas disease experts and other local stakeholders. The project is set to start in the first half of 2024.<br /><br />
          
          InChagas aims to contribute to strengthening local interventions and strategies to improve the comprehensive care and clinical management of people with Chagas cardiomyopathy.<br />
          This study is supported by the World Heart Federation's Emerging Leaders Project.
          `,
                    source: `<strong>Population source</strong>: 2022 Census.<br />
          <strong>Data source</strong>: National Surveillance System (SNVS 2.0).<br />
          These data were obtained from total values by category, and for the calculation of the proportion by sex, population projections from INDEC (Instituto Nacional de Estadística y Censos de la República Argentina) were used.`,
                },
            },
            brasil: {
                name: `Brazil`,
                description: `Brazil has played a leading role in the regional fight against Chagas disease, a disease discovered by the Brazilian Carlos Chagas in 1909. Currently, the country is in the process of implementing the notification of chronic cases. It is also the stage for the <a href="https://cuidachagas.org/" target="_blank">CUIDACHAGAS</a> project and has recently launched the <a href="https://www.gov.br/saude/pt-br/assuntos/brasil-saudavel" target="_blank">Healthy Brazil Program</a>, an ambitious initiative which includes Chagas disease. <br /><br />Quantitative data for 2023 has not been collected, this is preliminary data and may not reflect the country's reality regarding the epidemiological scenario this year. There may be some duplication of data for pregnant women.`,
                questions: {
                    moreInfo: `RAISE study: the burden of Chagas disease<br /><br />

          Professor Tom Ribeiro is a cardiologist at the University Hospital of the Federal University of Minas Gerais (UFMG) and much of his work involves seeing Chagas disease patients therefore when a patient presents with heart failure. <br /><br />
          
          Chagas disease always appears on the list of possible underlying causes. However this is often not the case and the fact Chagas disease is often overlooked as a differential diagnosis means that Chagas patients and their families often receive poorer quality health care. Tom also explains that "when it comes to filling out death certificates for people who die of heart disease, Chagas is also forgotten". Death certificates are a very important source of data and information, and too often Chagas disease is misclassified as other diseases affecting the heart. <br /><br />
          
          To better understand these issues, Ribeiro and the team at the Federal University of Minas Gerais - Brazil, launched the RAISE project ("The burden of Chagas disease in the contemporary world"), which is supported by the World Heart Federation, Novartis Global Health and the University of Washington Health Institute. The project also aims to estimate the burden of disease and congestive heart failure in order to estimate the costs associated with Chagas disease and other specific complications. We hope that the results of this study will contribute to breaking the epidemiological silence on the disease.<br /><br />
          
          Social participation: the protagonism of Brazilian decision-makers<br /><br />
          
          The Sistema Único de Saúde (SUS), Brazil's public health system is the result of a long fight for better access to health services for all in Brazil. Despite the obvious progress made in terms of universal access, major challenges remain, especially with regard to socially determined diseases such as Chagas disease. In this context, the emergence of initiatives such as the Fórum Social para Enfrentamento de Doenças Infecciosas e Negligenciadas (FSBEIN) Brazil's Social Forum for Addressing Neglected and Infectious Diseases is extremely important. This forum not only encourages  debate on these diseases, but also promotes the interactions between different social, governmental and scientific actors, seeking effective and inclusive solutions.<br /><br />
          
          In fact, as a result of FSBEIN, an association of people affected by socially determined diseases, the National Movement of Neglected Diseases (MNDN), was born. According to João Victor Pacheco, one of the founding members, "MNDN is a strong response to the injustices faced by communities affected by diseases such as leprosy, tuberculosis, filariasis, leishmaniasis, schistosomiasis, Chagas and others. It is more than a movement, it is a promise of hope and transformation. A call to action for all those who share our vision of a world where health and dignity are accessible to all, regardless of their social or economic status".
          `,
                    source: `<strong>Source for population</strong>: IBGE - Census 2022.<br />
          Source for Chagas prevalence: II Brazilian Consensus on Chagas disease, 2015.<br />
          <strong>Data source</strong>:<br />
          - Environmental Directorate of Laboratories (Gerenciador de Ambiente Laboratorial).<br />
          - Notifiable Diseases Information System - Sinan (Sistema de Informação de Agravos de Notificação).
          
          `,
                },
            },
            colombia: {
                name: `Colombia`,
                description: `Colombia has shown strong leadership in Latin America for the control and elimination of Chagas disease as a public health problem. The country has shown real commitment to reaching populations in hard to access areas that have been previously cut off due to armed conflict.`,
                questions: {
                    moreInfo: `Roadmap on comprehensive care for Chagas Disease <br /><br />

          The Rutas Integrales de Atención en Salud (RIAS), or 'roadmap' for Chagas Disease includes comprehensive guidelines and protocols for the diagnosis, treatment and follow-up.<br /><br />
          
          The pilot experience in Colombia, led by the Ministry of Health and Social Protection, the National Institute of Health and several endemic departments in the country, and supported by DNDi, has shown that through the implementation of the RIAS it is possible to streamline the diagnostic process and reduce the waiting time for the initiation of treatment, strengthening local systems and contributing to the control of Chagas disease. The implementation of the roadmap has increased the number of people with access to diagnosis and reduced the average time to definitive diagnosis from 258 to 19 days and the time between diagnosis and initiation of treatment from 354 to 127 days, a 64% reduction over the three-year duration of the pilot projects.<br /><br />
          
          An economic analysis conducted by the pilot project found that increased access to healthcare by decentralizing treatment reduced travel time and expense to attend medical appointments at specialized referral hospitals, which may be far from the communities in which they live. In these cases where care is only available at referral centers, there is a significant increase in patient costs (an average of 68,453 Colombian pesos - US$18.00 - and/or more than two days of lost income). These additional, non-medical costs correspond to expenses such as accommodation, food, transport and other services, which are not normally incurred when the patient receives care at the primary health care level.<br /><br />
          
          RIAS at the national level and the publication of clinical guidelines<br /><br />
          
          However, the RIAS for Chagas disease in Colombia has not yet been implemented at the national level, while we await the publication of the Clinical Guideline for Comprehensive Chagas Care, which will provide guidance for healthcare actors in the process of providing comprehensive care for the disease. We look forward to celebrating both of these advances, which will strengthen primary care and guarantee decentralized access to timely diagnosis and treatment.
          `,
                    source: `<strong>Source population</strong>: National Administrative Department of Statistics - DANE, published in March 2023.<br />
          <strong>Source Chagas prevalence</strong>: Olivera MJ, Fory JA, Porras JF, Buitrago G (2019) Prevalence of Chagas disease in Colombia: A systematic review and meta-analysis. PLoS ONE 14(1): e0210156<br />
          <strong>Source population at risk of Chagas disease</strong>: Ministry of Health and Social Protection, Integrated management group for endemic-epidemic diseases.<br />
          <strong>Data source</strong>:<br />
          - Collective screening ETV programmes, Colombia; ETV programmes, EMTCT-PLUS strategy, consolidated by Ministry of Health and Social Protection Colombia; National network of blood banks, National Institute of Health- INS.<br />
          - Departmental ETV programmes, consolidated by the Ministry of Health and Social Protection Colombia.
        `,
                },
            },
            guatemala: {
                name: `Guatemala`,
                description: `Chagas disease is a priority public health problem in Guatemala, being the Central American country with the highest burden of the disease and acute cases. In recent years, the country has made significant progress in terms of decentralizing diagnosis, and efforts are underway to strengthen this capacity, as well as treatment in endemic regions of the country.`,
                questions: {
                    moreInfo: `Generating evidence for the diagnostic algorithm.<br /><br />

          The Alianzas Project was an inter-institutional initiative launched in 2018 with the aim of  eliminating  Chagas disease as a public health problem in Guatemala. Specifically, in relation to diagnosis, the project helped lay the groundwork to strengthen the National Health Laboratory (LNS) and decentralize the diagnosis of Chagas disease. Since then, other initiatives have emerged with the same purpose, led by local authorities.<br /><br />
          
          In order to contribute to the evidence on the quality of diagnostic processes and complement the current diagnostic algorithm in the country, the LNS conducted a study in 2021 to evaluate 7 different serological tests available in Guatemala, for the detection of anti-T.cruzi IgG antibodies. Overall, three of the seven methodologies evaluated showed acceptable sensitivity and all had the ability to classify a healthy individual as negative. In an effort to further improve the country's diagnostic capacity, another study was conducted in 2023 to compare the performance of three rapid tests for use in the field, however none of them achieved sufficient sensitivity. Further studies are planned or ongoing to further analyze the performance of rapid tests.<br /><br />
          
          Decentralized care and EMTCT-PLUS<br /><br />
          
          With the opening of the decentralized laboratory for the diagnosis of Chagas disease in the department of El Quiché in December 2023, there are now four such laboratories in the interior of Guatemala to strengthen diagnosis at the national level. Decentralization of diagnostic capacity is vital for controlling  the disease, as it increases access to timely diagnosis, provides training for the health team, and promotes community outreach and awareness-raising activities, as well as primary health care.<br /><br />
          
          In addition to strengthening primary care, by 2024, the country's database is expected to be improved with information on the sex and age of people on the treatment register. There are also plans to increase testing and treatment for women of childbearing age and pregnant women, to achieve the goals of the EMTCT-plus (Elimination of Mother-to-Child Transmission) initiative.
          `,
                    source: `<strong>Population Source</strong>: <a href="https://datatopics.worldbank.org/world-development-indicators/" target="_blank">https://datatopics.worldbank.org/world-development-indicators/</a><br />
          <strong>Data source</strong>:<br />
          - National Health Laboratory Directorate and SIGSA Health Management System databases.<br />
          - Database of the Ministry of Public Health and Social Assistance's Vector-Borne Diseases Programme.<br />
          - Databases of national hospitals and health areas at national level.
        `,
                },
            },
            paraguai: {
                name: `Paraguay`,
                description: `The country has made significant progress in controlling Chagas disease, having increased the number of people treated over the past 3 years, as well as diagnostic capacity by integrating rapid tests into the algorithm. Likewise, medical personnel have received better training. All this has been achieved with the support of cooperation projects. Currently, there is an emphasis on diagnosing and treating women of childbearing age, especially pregnant women.`,
                questions: {
                    moreInfo: `Achievements and challenges in the elimination of transmission<br /><br />

          In recent decades, Paraguay has made many advances in vector control. Studies conducted in the 1970s showed significant levels of domestic infestation by triatomines, with 80% infestation detected in the Paraguayan Chaco. It was through its participation in the Southern Cone Initiative for the elimination of Triatoma infestans and the Chagas Disease Vector Control Plan in the 1990s that the country achieved international certification of interruption of vector transmission in 2018. Currently, the country has no reported cases of vector-borne or oral forms of transmission. There are also no reported cases via blood donations (in blood banks the prevalence of positive cases detected is 1.5-2%). Only congenital transmission is still present and it is the focus of continued attention.<br /><br />
          
          Although Paraguay began taking measures to control vertical transmission in 1995 there is still today a significant gap in testing coverage of pregnant women, which is less than 20%. Taking into account the estimated prevalence (5% in pregnant women - about 7,500 women) and the vertical transmission rate, an estimated 400 babies are born with the infection each year, of which only 8% are detected.<br /><br />
          
          [<a href="https://senepa.gov.py/2021/07/09/paraguay-refuerza-la-lucha-contra-la-enfermedad-de-chagas-con-las-primeras-guias-de-manejo-de-la-patologia/#:~:text=En%20Paraguay%2C%20actualmente%2C%20la%20principal,al%20a%C3%B1o%20con%20la%20infecci%C3%B3n" target="_blank">reference</a>]<br /><br />
          
          As part of the country's entry into the EMTCT-Plus initiative, Paraguay reinforces its commitment to increase routine serological testing of pregnant women throughout the country, as well as screening for the diseases included in this initiative to control mother-to-child transmission of syphilis, hepatitis, HIV and Chagas disease. In the same vein, the country is part of the CUIDA Chagas Project, whose main objective is the elimination of vertical transmission of the disease.`,
                    source: `<strong>Population Source</strong>: <a href="https://datatopics.worldbank.org/world-development-indicators/" target="_blank">https://datatopics.worldbank.org/world-development-indicators/</a> <br />
        <strong>Data Source</strong>: National Chagas Program - Ministry of Health of Paraguay.`,
                },
            },
            reinounido: {
                name: `UK/London`,
                description: `According to the 2021 Census, the United Kingdom is home to nearly 300,000 migrants born in Chagas-endemic countries, with over half residing in London. Blood donors from endemic regions have been screened for T. cruzi since the 1980s; however, the vast majority of individuals affected by Chagas in the UK are estimated to remain undiagnosed.`,
                questions: {
                    moreInfo: `The UK Chagas Hub<br /><br />

          Although people with Chagas disease have been cared for at the Hospital for Tropical Diseases (HTD) in London for many years, it was in 2017 that coordinated efforts to adequately address this neglected disease began, through the emergence of a multidisciplinary group called the UK Chagas Hub. The Hub is a collaboration that brings together health professionals, researchers, advocates and members of the Latin American community to address Chagas disease in the UK and contribute to global efforts to eliminate it. The HTD offers diagnostic and treatment services in a specialised Chagas disease clinic, the only one of its kind in the UK, and is run by Spanish-speaking staff. Although based in London, the Hub has recently begun to expand its operations to other parts of the country.<br /><br />
          
          The Hub is a member of the London Neglected Tropical Diseases Research Centre and the Global Chagas Coalition, and focuses on three main activities: raising awareness of Chagas disease among the Latin American community in the UK and health professionals; improving clinical services for people with or at risk of Chagas disease; and conducting research into the epidemiology and clinical manifestations of the disease.<br /><br />
          
          Community engagement<br /><br />
          
          Aware of the need to better understand its target population, in 2020 the UK Chagas Hub was able to refine its methods for engaging communities and tailor its future projects for greater effectiveness. To this end, in 2022 the Hub launched a pilot community screening programme in which it collaborates with local Latin American non-profit organizations to offer free Chagas testing at community events. To date, approximately 100 new cases have been detected out of a total of 400 tests performed.<br /><br />
          
          The Hub's current priority is to reach and test more diverse migrant groups and to continue to conduct research and studies to better understand the disease and its impact on Latin American communities in the UK.
          `,
                    source: `<strong>Population and at-risk population source</strong>: ONS Census, 2021.<br />
                <strong>Data source</strong>: LSHTM-sponsored research studies or UK Chagas Hub community initiatives (data provided by study investigators: N Elkheir/D Moore).`,
                },
            },
            suica: {
                name: `Canton of Geneva/Switzerland`,
                description: `Chagas disease was an unknown entity in Geneva until the year 2000 when a series of cases of heart failure in individuals of Latin American origin presenting at the Hopitaux Universitaire de Geneve (HUG) led doctors to investigate further. Suspecting a common underlying cause for these cases, centers of excellence in tropical diseases were consulted, and additional tests identified Chagas disease as the culprit.`,
                questions: {
                    moreInfo: `A rapid response to an alarming situation<br /><br />

          Geneva has a considerable number of people of Latin American origin, especially Bolivians. Faced with a spate of unexplained cases of heart failure in this population group, some years ago, medical staff carried out epidemiological investigations which revealed that a significant proportion of Latin American pregnant women were Chagas positive. It was also revealed that some patients who tested positive for Chagas were blood donors, in a country where blood bank screening did not include Chagas testing.<br /><br />
          
          Faced with this alarming reality and the likelihood of identifying more people living with Chagas disease, an informal group of health professionals from different disciplines was formed and began actively visiting affected communities. By informing and providing training to family doctors, as well as cardiologists and obstetricians serving the Latin American diaspora in Geneva, the group was able to disseminate information to at-risk populations and refer them to the services offered at the HUG (Hopitaux Universitaire de Geneve).<br /><br />
          
          Many of those affected by the disease are migrants without documentation or health insurance, which makes it difficult for them to access medical care for fear of interacting with public services. The HUG team has worked hard to create a climate of trust that facilitates the acceptance by patients and their families of the services offered by the hospital. The communities affected have mobilized to raise awareness among the local population, and in 2014 a support group for those affected by Chagas disease (AswissChagas) was created to reinforce the awareness-raising work.<br /><br />
          
          Challenges and future priorities<br /><br />
          
          HUG remains today the only place in the canton of Geneva that provides care for people affected by the disease, despite the fact that neither of the two antiparasitic drugs is registered in the country. At present, there is no official Chagas programme, or  legislation covering  the disease and it is not notifiable by law. Although all pregnant women of Latin American origin are tested at the HUG, it is not systematically applied across Switzerland.<br /><br />

          In this context, the Swiss Chagas Network was formed in 2023 with the goal of eliminating Chagas disease as a public health problem in the country by 2030, in line with the Roadmap for Neglected Tropical Diseases 2021-2030 that was adopted at the World Health Assembly in November 2020. The Network includes experts in the field of Chagas disease and covers relevant topics such as detection, diagnosis, treatment, prevention and control, with the ambition to work in all cantons of the country.<br /><br />
          
          One of the priorities for better control of the disease in the country is to establish a legislative and policy framework for Chagas disease, both in Geneva and at the national level in Switzerland to improve case detection and clinical management. Access to medicines is also a real challenge due to lack of registration. Last but not least, strengthening civil society organizations to enable greater mobilization of affected communities themselves will certainly help to address the stigma associated with Chagas disease, facilitate greater access to health care and ensure that policies and interventions to improve care actually meet the needs of affected people.
          `,
                    source: `<strong>Population source</strong>: Eurostat 2019.<br />
                <strong>Data source</strong>: Chagas Disease Program Report, National Surveillance System.
              `,
                },
            },
            menu: {
                population: `General Population`,
                pregnant: `Pregnant`,
                children: `Youth and Children`,
                assistance: `Assistance`,
                actions: `Actions`,
                more: `Read more`,
            },
            intro: {
                label: {
                    population: `Population`,
                    prevalence: `National Prevalence for Chagas`,
                    risk_population: `Population at Risk of Chagas`,
                    risk_vectorial: `Population at risk from vector transmission`,
                },
                data: {
                    population: {
                        argentina: `45,892,245 - The population at risk is spread across the 24 provinces of the country due to migratory flows.`,
                        brasil: `203,080,756`,
                        colombia: `51,682,692`,
                        guatemala: `17,110,000`,
                        paraguai: `6,704,000`,
                        reinounido: `59.6 million (UK)`,
                        suica: `499,480`,
                    },
                    prevalence: {
                        argentina: ``,
                        brasil: `~1.9 million to 4.6 million people`,
                        colombia: `2.0% (95%CI: 1.0-4.0)`,
                        guatemala: ``,
                        paraguai: ``,
                        reinounido: ``,
                        suica: ``,
                    },
                    riskPopulation: {
                        argentina: ``,
                        brasil: ``,
                        colombia: `11,983,942`,
                        guatemala: ``,
                        paraguai: ``,
                        reinounido: `283,721 individuals born in 21 endemic countries and currently residing in England and Wales.`,
                        suica: ``,
                    },
                    riskVectorial: {
                        argentina: ``,
                        brasil: ``,
                        colombia: `8,831,294`,
                        guatemala: ``,
                        paraguai: ``,
                        reinounido: ``,
                        suica: ``,
                    },
                },
            },
        },
        questions: {
            tested: {
                2021: {
                    argentina: {
                        q: `People tested by age`,
                        l: "0-1 years, 1-15 years, 15-45 years, 45+ years",
                    },
                    brasil: {
                        q: `People tested by age`,
                        l: "0-1 years, 1-15 years, 16-49 years, 50+ years",
                    },
                    colombia: {
                        q: `People tested by age`,
                        l: "< 5 years, 5-14 years, 15-17 years, 18+ years",
                    },
                    guatemala: {
                        q: `People tested by age`,
                        l: "0-1 years, 1-15 years, 15-49 years, 50+ years",
                    },
                    paraguai: {
                        q: `People tested by age`,
                        l: "0-1 years, 1-15 years, 15-49 years, 50+ years",
                    },
                    reinounido: {
                        q: `People tested by age`,
                        l: "0-1 years, 1-15 years, 15-49 years, 50+ years",
                    },
                    suica: {
                        q: `People tested by age`,
                        l: "0-1 years, 1-15 years, 15-49 years, 50+ years",
                    },
                },
                2022: {
                    argentina: {
                        q: `People tested by age`,
                        l: "0-1 years, 1-15 years, 15-45 years, 45+ years, Unspecified",
                    },
                    brasil: {
                        q: `People tested by age`,
                        l: "0-1 years, 1-15 years, 16-49 years, 50+ years",
                    },
                    colombia: {
                        q: `People tested by age`,
                        l: "< 5 years, 5-14 years, 15-17 years, 18+ years",
                    },
                    guatemala: {
                        q: `People tested by age`,
                        l: "0-1 years, 1-15 years, 15-49 years, 50+ years",
                    },
                    paraguai: {
                        q: `People tested by age`,
                        l: "0-1 years, 1-15 years, 15-49 years, 50+ years",
                    },
                    reinounido: {
                        q: `People tested by age`,
                        l: "0-1 years, 1-15 years, 15-49 years, 50+ years",
                    },
                    suica: {
                        q: `People tested by age`,
                        l: "0-1 years, 1-15 years, 15-49 years, 50+ years",
                    },
                },
                2023: {
                    argentina: {
                        q: `People tested by age`,
                        l: "0-1 years, 1-15 years, 15-45 years, 45+ years, Not Specified",
                    },
                    brasil: {
                        q: `People tested by age`,
                        l: "0-1 years, 1-15 years, 16-49 years, 50+ years",
                    },
                    colombia: {
                        q: `People tested by age`,
                        l: "< 5 years, 5-14 years, 15-17 years, 18+ years",
                    },
                    guatemala: {
                        q: `People tested by age`,
                        l: "0-1 years, 1-15 years, 15-49 years, 50+ years, Unclassified",
                    },
                    paraguai: {
                        q: `People tested by age`,
                        l: "0-1 years, 1-15 years, 15-49 years, 50+ years",
                    },
                    reinounido: {
                        q: `People tested by age`,
                        l: "0-1 years, 1-15 years, 15-49 years, 50+ years",
                    },
                    suica: {
                        q: `People tested by age`,
                        l: "0-1 years, 1-15 years, 15-49 years, 50+ years",
                    },
                },
            },
            genres: {
                2021: {
                    argentina: {
                        q: `People tested by gender`,
                        l: "Women, Men, Others",
                    },
                    brasil: {
                        q: `People tested by gender`,
                        l: "Women, Men, Others",
                    },
                    colombia: {
                        q: `People tested by gender`,
                        l: "Women, Men, Others",
                    },
                    guatemala: {
                        q: `People tested by gender`,
                        l: "Women, Men, Others",
                    },
                    paraguai: {
                        q: `People tested by gender`,
                        l: "Women, Men, Others",
                    },
                    reinounido: {
                        q: `People tested by gender`,
                        l: "Women, Men, Others",
                    },
                    suica: {
                        q: `People tested by gender`,
                        l: "Women, Men, Others",
                    },
                },
                2022: {
                    argentina: {
                        q: `People tested by gender`,
                        l: "Women, Men, Others",
                    },
                    brasil: {
                        q: `People tested by gender`,
                        l: "Women, Men, Others",
                    },
                    colombia: {
                        q: `People tested by gender`,
                        l: "Women, Men, Others",
                    },
                    guatemala: {
                        q: `People tested by gender`,
                        l: "Women, Men, Others",
                    },
                    paraguai: {
                        q: `People tested by gender`,
                        l: "Women, Men, Others",
                    },
                    reinounido: {
                        q: `People tested by gender`,
                        l: "Women, Men, Others",
                    },
                    suica: {
                        q: `People tested by gender`,
                        l: "Women, Men, Others",
                    },
                },
                2023: {
                    argentina: {
                        q: `People tested by gender`,
                        l: "Women, Men, Others",
                    },
                    brasil: {
                        q: `People tested by gender`,
                        l: "Women, Men, Others",
                    },
                    colombia: {
                        q: `People tested by gender`,
                        l: "Women, Men, Others",
                    },
                    guatemala: {
                        q: `People tested by gender`,
                        l: "Women, Men, Others",
                    },
                    paraguai: {
                        q: `People tested by gender`,
                        l: "Women, Men, Others",
                    },
                    reinounido: {
                        q: `People tested by gender`,
                        l: "Women, Men, Others",
                    },
                    suica: {
                        q: `People tested by gender`,
                        l: "Women, Men, Others",
                    },
                },
            },
            1: {
                2021: {
                    argentina: `<strong>[x] out of every [y]</strong> people tested received a <strong>positive</strong> diagnosis.`,
                    brasil: `<strong>[x]</strong> people tested received a <strong>positive</strong> diagnosis.`,
                    colombia: `<strong>[x] out of every [y]</strong> people tested received a <strong>positive</strong> diagnosis.`,
                    guatemala: `Approximately <strong>[x] out of every [y]</strong> people tested received a <strong>positive</strong> diagnosis.`,
                    paraguai: `<strong>[x] out of every [y]</strong> people tested received a <strong>positive</strong> diagnosis.`,
                    reinounido: `<strong>[x] out of every [y]</strong> people tested received a <strong>positive</strong> diagnosis.`,
                    suica: `<strong>[x] out of every [y]</strong> people tested received a <strong>positive</strong> diagnosis.`,
                },
                2022: {
                    argentina: `<strong>[x] out of every [y]</strong> people tested received a <strong>positive</strong> diagnosis.`,
                    brasil: `<strong>[x]</strong> people were tested, and of those, <strong>[y]</strong> received a <strong>positive</strong> diagnosis ([z]).`,
                    colombia: `<strong>[x] out of [y]</strong> people tested received a <strong>positive</strong> diagnosis.`,
                    guatemala: `Approximately <strong>[x] out of every [y]</strong> people tested received a <strong>positive</strong> diagnosis.`,
                    paraguai: `<strong>[x] out of every [y]</strong> people tested received a <strong>positive</strong> diagnosis.`,
                    reinounido: `<strong>[x] out of every [y]</strong> people tested received a <strong>positive</strong> diagnosis.`,
                    suica: `Approximately <strong>[x] out of every [y]</strong> people tested received a <strong>positive</strong> diagnosis.`,
                },
                2023: {
                    argentina: `<strong>[x] out of every [y]</strong> people tested received a <strong>positive</strong> diagnosis.`,
                    brasil: `<strong>[x]</strong> people were tested, and of those, <strong>[y]</strong> received a <strong>positive</strong> diagnosis ([z]).`,
                    colombia: `<strong>[x] out of every [y]</strong> people tested received a <strong>positive</strong> diagnosis.`,
                    guatemala: `<strong>[x] out of every [y]</strong> people tested received a <strong>positive</strong> diagnosis.`,
                    paraguai: `Approximately <strong>[x] out of every [y]</strong> people tested received a <strong>positive</strong> diagnosis.`,
                    reinounido: `<strong>[x] out of every [y]</strong> people tested received a <strong>positive</strong> diagnosis.`,
                    suica: `<strong>[x] out of every [y]</strong> people tested received a <strong>positive</strong> diagnosis.`,
                },
            },
            2: `<strong>[x]</strong> people diagnosed started <strong>treatment</strong>.`,
            "2t": {
                2023: {
                    argentina: ``,
                    brasil: ``,
                    colombia: ``,
                    guatemala: ``,
                    paraguai: ``,
                    reinounido: ``,
                    suica: ``,
                },
                2022: {
                    argentina: ``,
                    brasil: `24 patients presented only Congestive Heart Failure (CHF), 81 patients presented only arrhythmia, and 20 patients presented both CHF and arrhythmia.`,
                    colombia: ``,
                    guatemala: ``,
                    paraguai: ``,
                    reinounido: ``,
                    suica: ``,
                },
                2021: {
                    argentina: ``,
                    brasil: ``,
                    colombia: ``,
                    guatemala: `Guatemala has control over the distribution of vials of the medications Nifurtimox and Benznidazole. In this year of 2021, 729 vials of the two medications were distributed, equivalent to approximately 243 treated patients.`,
                    paraguai: ``,
                    reinounido: ``,
                    suica: ``,
                },
            },
            3: {
                2021: {
                    argentina: `Of the reported and classified cases, <strong>[x] are chronic</strong>.`,
                    brasil: `Of the reported and classified cases, <strong>[x] are acute</strong>.`,
                    colombia: `Of the reported and classified cases, <strong>[x] are chronic</strong>.`,
                    guatemala: `Of the reported and classified cases, <strong>[x] are chronic</strong>.`,
                    paraguai: `Of the reported and classified cases, <strong>[x] are chronic</strong>.`,
                    reinounido: `Of the reported and classified cases, <strong>[x] are chronic</strong>.`,
                    suica: `Of the reported and classified cases, <strong>[x] are chronic</strong>.`,
                },
                2022: {
                    argentina: `Approximately <strong>[x] out of [y]</strong> cases were considered chronic.`,
                    brasil: `Of the reported and classified cases, <strong>[x] are acute</strong>.`,
                    colombia: `Of the reported and classified cases, <strong>[x] are chronic</strong>.`,
                    guatemala: `Of the reported and classified cases, <strong>[x] are chronic</strong>.`,
                    paraguai: `Of the reported and classified cases, <strong>[x] are chronic</strong>.`,
                    reinounido: `<strong>[x]</strong> of diagnosed cases are <strong>chronic</strong>.`,
                    suica: `<strong>[x]</strong> of diagnosed cases are <strong>chronic</strong>.`,
                },
                2023: {
                    argentina: `Of the reported and classified cases, <strong>[x] are chronic</strong>.`,
                    brasil: `<strong>[x] out of [y]</strong> were considered <strong>acute</strong>.`,
                    colombia: `Of the reported and classified cases, <strong>[x] are chronic</strong>.`,
                    guatemala: `Of the reported and classified cases, <strong>[x] are chronic</strong>.`,
                    paraguai: `Of the reported and classified cases, <strong>[x] are chronic</strong>.`,
                    reinounido: `Of the reported and classified cases, <strong>[x] are chronic</strong>.`,
                    suica: `Of the reported and classified cases, <strong>[x] are chronic</strong>.`,
                },
            },
            "3t": {
                2023: {
                    argentina: ``,
                    brasil: `Currently, the country classifies all cases as acute. Mandatory reporting of chronic cases is being implemented.`,
                    colombia: ``,
                    guatemala: ``,
                    paraguai: ``,
                    reinounido: ``,
                    suica: ``,
                },
                2022: {
                    argentina: ``,
                    brasil: `Currently, the country classifies all cases as acute. Mandatory reporting of chronic cases is being implemented.`,
                    colombia: ``,
                    guatemala: ``,
                    paraguai: ``,
                    reinounido: ``,
                    suica: ``,
                },
                2021: {
                    argentina: ``,
                    brasil: `Currently, the country classifies all cases as acute. Mandatory reporting of chronic cases is being implemented.`,
                    colombia: ``,
                    guatemala: ``,
                    paraguai: ``,
                    reinounido: ``,
                    suica: ``,
                },
            },
            4: `<strong>Transmission modes</strong>`,
            5: {
                2023: {
                    argentina: `Approximately <strong>[x] out of [y]</strong> people tested were <strong>pregnant</strong>.`,
                    brasil: `Approximately <strong>[x] out of [y]</strong> people tested were <strong>pregnant</strong>.`,
                    colombia: `<strong>[x] out of [y]</strong> people tested were <strong>pregnant</strong>.`,
                    guatemala: `<strong>[x] out of [y]</strong> people tested were <strong>pregnant</strong>.`,
                    paraguai: `Approximately <strong>[x] out of [y]</strong> people tested were <strong>pregnant</strong>.`,
                    reinounido: `<strong>[x] out of [y]</strong> people tested were <strong>pregnant</strong>.`,
                    suica: `<strong>[x] out of [y]</strong> people tested were <strong>pregnant</strong>.`,
                },
                2022: {
                    argentina: `Approximately <strong>[x] out of [y]</strong> people tested were <strong>pregnant</strong>.`,
                    brasil: `Approximately <strong>[x] out of [y]</strong> people tested were <strong>pregnant</strong>.`,
                    colombia: `Approximately <strong>[x] out of [y]</strong> people tested were <strong>pregnant</strong>.`,
                    guatemala: `<strong>[x] out of [y]</strong> people tested were <strong>pregnant</strong>.`,
                    paraguai: `Approximately <strong>[x] out of [y]</strong> people tested were <strong>pregnant</strong>.`,
                    reinounido: `Out of <strong>267</strong> people tested, <strong>80</strong> were <strong>pregnant</strong>.`,
                    suica: `<strong>[x] out of [y]</strong> people tested were <strong>pregnant</strong>.`,
                },
                2021: {
                    argentina: `Approximately <strong>[x] out of [y]</strong> people tested were <strong>pregnant</strong>.`,
                    brasil: `<strong>[x]</strong> of the people tested were <strong>pregnant</strong>.`,
                    colombia: `<strong>[x] out of [y]</strong> people tested were <strong>pregnant</strong>.`,
                    guatemala: `<strong>[x] out of [y]</strong> people tested were <strong>pregnant</strong>.`,
                    paraguai: `<strong>[x] out of [y]</strong> people tested were <strong>pregnant</strong>.`,
                    reinounido: `<strong>[x] out of [y]</strong> people tested were <strong>pregnant</strong>.`,
                    suica: `<strong>[x] out of [y]</strong> people tested were <strong>pregnant</strong>.`,
                },
            },
            "5t": {
                argentina: ``,
                brasil: ``,
                colombia: ``,
                guatemala: `Underreported since the decentralized laboratory in Jutiapa does not register pregnant women.`,
                paraguai: ``,
                reinounido: ``,
                suica: ``,
            },
            6: {
                2023: {
                    argentina: `Out of <strong>[x]</strong> thousand pregnant women tested, approximately <strong>[y]</strong> tested positive.`,
                    brasil: `<strong>[x] out of [y]</strong> pregnant women tested positive.`,
                    colombia: `Out of <strong>[x]</strong> pregnant women tested, approximately <strong>[y]</strong> tested positive.`,
                    guatemala: `Out of <strong>[x]</strong> pregnant women tested, <strong>[y]</strong> tested positive.`,
                    paraguai: `<strong>[y]</strong> pregnant women tested positive.`,
                    reinounido: `Out of <strong>[x]</strong> pregnant women tested, approximately <strong>[y]</strong> tested positive.`,
                    suica: `Out of <strong>[x]</strong> pregnant women tested, approximately <strong>[y]</strong> tested positive.`,
                },
                2022: {
                    argentina: `Out of <strong>[x]</strong> thousand pregnant women tested, approximately <strong>[y]</strong> tested positive.`,
                    brasil: `Only <strong>[y]</strong> pregnant women tested positive.`,
                    colombia: `<strong>[y]</strong> of the pregnant women tested positive.`,
                    guatemala: `<strong>1 in [y]</strong> pregnant women tested positive.`,
                    paraguai: `<strong>[y]</strong> pregnant women tested positive.`,
                    reinounido: `Out of <strong>[x]</strong> pregnant women tested, <strong>0</strong> (none) tested positive.`,
                    suica: `<strong>[y]</strong> pregnant woman tested positive.`,
                },
                2021: {
                    argentina: `<strong>[y]</strong> of the pregnant women tested positive.`,
                    brasil: `<strong>[y]</strong> of the pregnant women tested positive.`,
                    colombia: `Out of <strong>[x]</strong> pregnant women tested, approximately <strong>[y]</strong> tested positive.`,
                    guatemala: `<strong>[y]</strong> pregnant women tested positive.`,
                    paraguai: `<strong>[y]</strong> of the pregnant women tested positive.`,
                    reinounido: `Out of <strong>[x]</strong> pregnant women tested, approximately <strong>[y]</strong> tested positive.`,
                    suica: `Out of <strong>[x]</strong> pregnant women tested, approximately <strong>[y]</strong> tested positive.`,
                },
            },
            "6t": {
                argentina: ``,
                brasil: ``,
                colombia: ``,
                guatemala: `Underreported since the decentralized laboratory in Jutiapa does not register pregnant women.`,
                paraguai: ``,
                reinounido: ``,
                suica: ``,
            },
            7: {
                2023: {
                    argentina: `<strong>[x]</strong> pregnant women started the <strong>treatment</strong>.`,
                    brasil: `<strong>[x]</strong> of the pregnant women diagnosed started <strong>treatment</strong>.`,
                    colombia: `<strong>[x]</strong> pregnant women started the <strong>treatment</strong>.`,
                    guatemala: `<strong>[x]</strong> pregnant women started the <strong>treatment</strong>.`,
                    paraguai: `<strong>[x]</strong> pregnant women started the <strong>treatment</strong>.`,
                    reinounido: `<strong>[x]</strong> pregnant women started the <strong>treatment</strong>.`,
                    suica: `<strong>[x]</strong> pregnant women started the <strong>treatment</strong>.`,
                },
                2022: {
                    argentina: `<strong>[x]</strong> pregnant women started the <strong>treatment</strong>.`,
                    brasil: `<strong>[x]</strong> of the pregnant women diagnosed started <strong>treatment</strong>.`,
                    colombia: `<strong>[x]</strong> pregnant women started the <strong>treatment</strong>.`,
                    guatemala: `<strong>[x]</strong> pregnant women started the <strong>treatment</strong>.`,
                    paraguai: `<strong>[x]</strong> pregnant women started the <strong>treatment</strong>.`,
                    reinounido: `<strong>[x]</strong> pregnant women started the <strong>treatment</strong>.`,
                    suica: `<strong>[x]</strong> pregnant women started the <strong>treatment</strong>.`,
                },
                2021: {
                    argentina: `<strong>[x]</strong> pregnant women started the <strong>treatment</strong>.`,
                    brasil: `<strong>[x]</strong> of the pregnant women diagnosed started <strong>treatment</strong>.`,
                    colombia: `<strong>[x]</strong> pregnant women started the <strong>treatment</strong>.`,
                    guatemala: `<strong>[x]</strong> pregnant women started the <strong>treatment</strong>.`,
                    paraguai: `<strong>[x]</strong> pregnant women started the <strong>treatment</strong>.`,
                    reinounido: `<strong>[x]</strong> pregnant women started the <strong>treatment</strong>.`,
                    suica: `<strong>[x]</strong> pregnant women started the <strong>treatment</strong>.`,
                },
            },
            "7t": {
                2021: {
                    argentina: `This year, Argentina only registered the beginning of treatment for pregnant women.`,
                    brasil: `The indicator corresponds to pregnant women who were diagnosed during pregnancy and whose treatment was provided after the delivery and breastfeeding period).`,
                    colombia: ``,
                    guatemala: `Guatemala has control over the distribution of bottles of Nifurtimox and Benznidazole medications. In this year of 2021, 729 bottles of the two medications were distributed, equivalent to approximately 243 treated patients.`,
                    paraguai: `55 were women and 45 men.`,
                    reinounido: ``,
                    suica: ``,
                },
                2022: {
                    argentina: ``,
                    brasil: `The indicator corresponds to pregnant women who were diagnosed during pregnancy and whose treatment was provided after the delivery and breastfeeding period).`,
                    colombia: `The indicator corresponds to pregnant women who were diagnosed during pregnancy and whose treatment was provided after the delivery and breastfeeding period).`,
                    guatemala: `Some of the people who started treatment were diagnosed in previous years.`,
                    paraguai: `There is no classification by age and sex, as well as if pregnant.`,
                    reinounido: `This country does not classify among the people who started treatment, sex, and age.`,
                    suica: `The person who started treatment was aged 15 to 49, not pregnant.`,
                },
                2023: {
                    argentina: ``,
                    brasil: `The indicator corresponds to pregnant women who were diagnosed during pregnancy and whose treatment was provided after the delivery and breastfeeding period).`,
                    colombia: ``,
                    guatemala: ``,
                    paraguai: `70 were female and 49 male.`,
                    reinounido: ``,
                    suica: ``,
                },
            },
            8: {
                2023: {
                    argentina: `Approximately <strong>[x] out of every [y]</strong> tested individuals were <strong>under [z] years old</strong>.`,
                    brasil: `Approximately <strong>[x] out of every [y]</strong> tested individuals were <strong>under [z] years old</strong>.`,
                    colombia: `<strong>[x] out of every [y]</strong> tested individuals were <strong>under [z] years old</strong>.`,
                    guatemala: `<strong>[x] out of every [y]</strong> tested individuals were <strong>under [z] years old</strong>.`,
                    paraguai: `Approximately <strong>[x] out of every [y]</strong> tested individuals were <strong>under [z] years old</strong>.`,
                    reinounido: `<strong>[x] out of every [y]</strong> tested individuals were <strong>under [z] years old</strong>.`,
                    suica: `<strong>[x] out of every [y]</strong> tested individuals were <strong>under [z] years old</strong>.`,
                },
                2022: {
                    argentina: `Approximately <strong>[x] out of every [y]</strong> tested individuals were <strong>under [z] years old</strong>.`,
                    brasil: `Approximately <strong>[x] out of every [y]</strong> tested individuals were <strong>under [z] years old</strong>.`,
                    colombia: `<strong>[x] out of every [y]</strong> tested individuals were <strong>under [z] years old</strong>.`,
                    guatemala: `<strong>[x] out of every [y]</strong> tested individuals were <strong>under [z] years old</strong>.`,
                    paraguai: `<strong>[x] out of every [y]</strong> tested individuals were <strong>under [z] years old</strong>.`,
                    reinounido: `Approximately <strong>[x] out of every [y]</strong> tested individuals were <strong>under [z] years old</strong>.`,
                    suica: `Approximately <strong>[x] out of every [y]</strong> tested individuals were <strong>under [z] years old</strong>.`,
                },
                2021: {
                    argentina: `<strong>[x] out of every [y]</strong> tested individuals were <strong>under [z] years old</strong>.`,
                    brasil: `<strong>1 out of every 10</strong> tested individuals were <strong>under [z] years old</strong>.`,
                    colombia: `<strong>[x] out of every [y]</strong> tested individuals were <strong>under [z] years old</strong>.`,
                    guatemala: `<strong>[x] out of every [y]</strong> tested individuals were <strong>under [z] years old</strong>.`,
                    paraguai: `<strong>[x]</strong> of tested individuals were <strong>under [z] years old</strong>.`,
                    reinounido: `<strong>[x] out of every [y]</strong> tested individuals were <strong>under [z] years old</strong>.`,
                    suica: `<strong>[x] out of every [y]</strong> tested individuals were <strong>under [z] years old</strong>.`,
                },
            },
            9: {
                2021: {
                    argentina: `<strong>[x]</strong> of the children under 15 years old who were tested received a <strong>positive diagnosis</strong>.`,
                    brasil: `Approximately <strong>2 out of every 100</strong> children under 15 years old tested received a <strong>positive diagnosis</strong>.`,
                    colombia: `<strong>[x]</strong> of the children under [z] years old who were tested and classified by age received a <strong>positive diagnosis</strong>.`,
                    guatemala: `<strong>[x]</strong> of the children under 15 years old who were tested received a <strong>positive diagnosis</strong>.`,
                    paraguai: `<strong>[x]</strong> children under 15 years old tested received a <strong>positive diagnosis</strong>.`,
                    reinounido: `<strong>[x]</strong> of the children under 15 years old who were tested received a <strong>positive diagnosis</strong>.`,
                    suica: `<strong>[x]</strong> of the children under 15 years old who were tested received a <strong>positive diagnosis</strong>.`,
                },
                2022: {
                    argentina: `<strong>[x]</strong> of the children under 15 years old who were tested received a <strong>positive diagnosis</strong>.`,
                    brasil: `Approximately <strong>2 out of every [x]</strong> children under 15 years old tested received a <strong>positive diagnosis</strong>.`,
                    colombia: `<strong>[x]</strong> of the children under [z] years old received a <strong>positive diagnosis</strong>.`,
                    guatemala: `Approximately <strong>7 out of every [x]</strong> children under 15 years old tested received a <strong>positive diagnosis</strong>.`,
                    paraguai: `<strong>[x]</strong> children under 15 years old received a <strong>positive diagnosis</strong>.`,
                    reinounido: `<strong>None</strong> of the children under 15 years old who were tested received a <strong>positive diagnosis</strong>.`,
                    suica: `<strong>[x]</strong> of the children under 15 years old who were tested received a <strong>positive diagnosis</strong>.`,
                },
                2023: {
                    argentina: `<strong>[x]</strong> of the children under 15 years old who were tested received a <strong>positive diagnosis</strong>.`,
                    brasil: `Approximately <strong>1 out of every [x]</strong> children under 15 years old tested received a <strong>positive diagnosis</strong>.`,
                    colombia: `<strong>[x]</strong> of the children under [z] years old who were tested and classified by age received a <strong>positive diagnosis</strong>.`,
                    guatemala: `<strong>[x]</strong> of the children under 15 years old who were tested received a <strong>positive diagnosis</strong>.`,
                    paraguai: `<strong>4 out of every [x]</strong> children under 15 years old tested received a <strong>positive diagnosis</strong>.`,
                    reinounido: `<strong>[x]</strong> of the children under 15 years old who were tested received a <strong>positive diagnosis</strong>.`,
                    suica: `<strong>[x]</strong> of the children under 15 years old who were tested received a <strong>positive diagnosis</strong>.`,
                },
            },
            10: {
                2023: {
                    argentina: `<strong>[x] </strong> children under [z] years old initiated <strong>treatment</strong>.`,
                    brasil: `<strong>[x] </strong> children under [z] years old initiated <strong>treatment</strong>.`,
                    colombia: `<strong>[x] </strong> children under [z] years old initiated <strong>treatment</strong>.`,
                    guatemala: `<strong>[x] </strong> children under [z] years old initiated <strong>treatment</strong>.`,
                    paraguai: `<strong>[x] </strong> children under [z] years old initiated <strong>treatment</strong>.`,
                    reinounido: `<strong>[x] </strong> children under [z] years old initiated <strong>treatment</strong>.`,
                    suica: `<strong>[x] </strong> children under [z] years old initiated <strong>treatment</strong>.`,
                },
                2022: {
                    argentina: `<strong>[x] </strong> children under [z] years old initiated <strong>treatment</strong>.`,
                    brasil: `<strong>94.4% ([x]) </strong> of the diagnosed youth initiated <strong>treatment</strong>.`,
                    colombia: `<strong>62.5% ([x]) </strong> of the children under [z] years old diagnosed initiated <strong>treatment</strong>.`,
                    guatemala: `<strong>[x] </strong> children under [z] years old initiated <strong>treatment</strong>.`,
                    paraguai: `<strong>[x] </strong> children under [z] years old initiated <strong>treatment</strong>.`,
                    reinounido: `<strong>[x] </strong> children under [z] years old initiated <strong>treatment</strong>.`,
                    suica: `<strong>[x] </strong> children under [z] years old initiated <strong>treatment</strong>.`,
                },
                2021: {
                    argentina: `<strong>[x] </strong> children under [z] years old initiated <strong>treatment</strong>.`,
                    brasil: `<strong>[x] </strong> children under [z] years old initiated <strong>treatment</strong>.`,
                    colombia: `<strong>[x] </strong> children under [z] years old initiated <strong>treatment</strong>.`,
                    guatemala: `<strong>[x] </strong> children under [z] years old initiated <strong>treatment</strong>.`,
                    paraguai: `<strong>[x] </strong> children under [z] years old initiated <strong>treatment</strong>.`,
                    reinounido: `<strong>[x] </strong> children under [z] years old initiated <strong>treatment</strong>.`,
                    suica: `<strong>[x] </strong> children under [z] years old initiated <strong>treatment</strong>.`,
                },
            },
            11: `Is there a systematic <strong>planning</strong> and demand forecasting for obtaining medications and diagnostic tests?`,
            12: `Do both nifurtimox and benznidazole have a valid <strong>registration</strong> in the country?`,
            "12t": {
                argentina: ``,
                brasil: `Nifurtimox is not yet registered.`,
                colombia: `Benznidazole is not yet registered.`,
                guatemala: ``,
                paraguai: ``,
                reinounido: `Both Benznidazole and Nifurtimox are not yet registered in the country.`,
                suica: ``,
            },
            13: `Are both nifurtimox and benznidazole <strong>available</strong> in the country?`,
            "13t": {
                argentina: ``,
                brasil: ``,
                colombia: ``,
                guatemala: ``,
                paraguai: ``,
                reinounido: `Benznidazole is available through the WHO - Geneva. However, there have been recent supply issues due to the rejection of the import of the medication from Argentina (ELEA/Mundo Sano) by the European Medicines and Healthcare products Regulatory Agency.`,
                suica: `Neither of the drugs is registered, but they can be requested in case of need. Only the University Hospital of Geneva does this regularly.`,
            },
            14: `Does the country utilize <strong>rapid diagnostic tests</strong>?`,
            "14t": {
                argentina: ``,
                brasil: `Rapid diagnostic tests are in the process of being incorporated by the IntegraChagas project. SUS still recommends serology for the diagnosis of CD in the country. Diagnostic tests with registration in the country: Bio-manguinhos, Alere Chagas®, AbCombo®, and WL Check Chagas.`,
                colombia: ``,
                guatemala: ``,
                paraguai: `Diagnostic tests with registration in the country: Chagas Stat-Pak assay (Chemio - USA), WL Check Chagas (Wiener Lab - Argentina), Nova Test (China), Artron (Canada), All test Biotech (China). Suppliers have a valid export/import permit for 1 year, renewable. Rapid tests are validated and incorporated into the country's diagnostic algorithm.`,
                reinounido: `Diagnostic tests with registration in the country: Stat Pak (ChemBio) and Inbios CDP.`,
                suica: `Diagnostic test with registration in the country: Stat Pak (ChemBio).`,
            },
            15: `Is evaluation for cardiological complications and other possibilities carried out at the <strong>primary health care</strong>?`,
            "15t": {
                argentina: ``,
                brasil: `Cardiological evaluation is recommended by the PCDT, but its execution by the PHC is evaluated through the Secretary of Primary Health Care.`,
                colombia: ``,
                guatemala: `At the first level of care, no cardiological evaluation or evaluation of other possible complications is carried out.`,
                paraguai: `Only an Electrocardiogram is performed in primary health care.`,
                reinounido: `Cardiological evaluation carried out in secondary care.`,
                suica: ``,
            },
            16: `Is there a <strong>National Program</strong> or active Subprogram for Chagas Disease?`,
            17: `Is there a <strong>budget</strong> from the Ministry of Health dedicated to the Chagas Program?`,
            "17t": {
                argentina: ``,
                brasil: ``,
                colombia: ``,
                guatemala: `There is no dedicated budget for Chagas Disease. The budget is for the entire Vector Program, of which Chagas is part along with other diseases.`,
                paraguai: ``,
                reinounido: ``,
                suica: ``,
            },
            18: `Is there a <strong>national guideline</strong> published for diagnosis and treatment?`,
            "18t": {
                argentina: `Last updated in 2018. <a target="_blank" href="https://bancos.salud.gob.ar/sites/default/files/2020-01/chagas-atencion-paciente-infectado-2018.pdf">Link</a>`,
                brasil: `Last updated in 2018. <a target="_blank" href="https://www.gov.br/conitec/pt-br/midias/protocolos/resumidos/PCDTResumidoDoenadeChagas.pdf">Link</a>`,
                colombia: `The country adopted the Guide for the diagnosis and treatment of Chagas disease, from the Pan American Health Organization. Washington, DC: PAHO; 2018.`,
                guatemala: `In 2021, the Operational Manual for Surveillance and Entomological Control of Chagas Disease was published. In 2023, the Comprehensive Health Care Standards for the First and Second Levels were published.`,
                paraguai: `Last updated in 2021.`,
                reinounido: `Health guide for migrants in the United Kingdom, published in 2014, updated in 2021.`,
                suica: ``,
            },
            19: `Does the country have <strong>forums for civil society participation</strong> in formulating policies for disease control and elimination?`,
            20: `Are there <strong>official associations</strong> for affected individuals?`,
            "20t": {
                argentina: ``,
                brasil: ``,
                colombia: ``,
                guatemala: ``,
                paraguai: ``,
                reinounido: ``,
                suica: `The SwissChagas was created in 2015. Contact: <a href="mailto:aswisschagas@hotmail.com">aswisschagas@hotmail.com</a>`,
            },
            21: `Is <strong>Chagas World Day</strong> celebrated?`,
            "21t": {
                argentina: `Two dates are celebrated: the Chagas World Day on April 14th and the National Day on the last Friday of August of each year.`,
                brasil: `In this country, the celebration takes place every April 14th.`,
                colombia: `Two dates are celebrated: World Day on April 14th, National Day on the last day of August each year.`,
                guatemala: `In this country, the World Chagas Day is officially celebrated on April 14th and July 9th.`,
                paraguai: `In this country, the celebration takes place every April 14th.`,
                reinounido: `In this country, the celebration takes place every April 14th.`,
                suica: ``,
            },
            22: `Does the country implement <strong>EMTCT-Plus</strong>?`,
            "22t": {
                general: `EMTCT-Plus is the framework for the elimination of mother-to-child transmission of HIV, syphilis, hepatitis B, and Chagas Disease.`,
                argentina: `The country is part of the initiative.`,
                brasil: ``,
                colombia: ``,
                guatemala: `The country intends to include Chagas in the strategy, however, they still need to validate and make rapid tests available nationwide.`,
                paraguai: ``,
                reinounido: ``,
                suica: ``,
            },
            23: `Do people diagnosed through <strong>blood donations</strong> receive follow up?`,
            "23t": {
                argentina: ``,
                brasil: ``,
                colombia: `Colombia has established goals in the ten-year public health plan 2022 - 2031. Among the goals is the monitoring of individuals who test positive also for blood banks.`,
                guatemala: ``,
                paraguai: `Currently, the country is in internal discussions and alignment to initiate screening.`,
                reinounido: ``,
                suica: ``,
            },
            24: `Is <strong>intra-domiciliary vector transmission</strong> interrupted and certified in all endemic areas?`,
            "24t": {
                argentina: ``,
                brasil: `Brazil is certified for the elimination of T. infestans; however, other species of triatomines still colonize the intra-domiciliary environment.`,
                colombia: `In the year 2022, 66 municipalities were/are part of the interruption plan, and another 33 municipalities are to be incorporated.`,
                guatemala: `In 2019, Guatemala was certified for the elimination of Rhodnius prolixus, one of the vectors of Chagas disease in the country.`,
                paraguai: ``,
                reinounido: ``,
                suica: ``,
            },
            25: `Does the country <strong>record acute cases</strong> at the national level?`,
            "25t": {
                argentina: ``,
                brasil: ``,
                colombia: ``,
                guatemala: `The country recognizes the need to reinforce active search and epidemiological surveillance activities to increase the number of detected acute cases.`,
                paraguai: ``,
                reinounido: ``,
                suica: ``,
            },
            26: `Does the country <strong>record chronic cases</strong> at the national level?`,
            "26t": {
                argentina: ``,
                brasil: `In the process of implementation`,
                colombia: `Chronic cases are partially reported to the national epidemiological surveillance system. Since 2018, only cases in individuals under 18 years old, indigenous people, pregnant women, and women of childbearing age have been registered.`,
                guatemala: ``,
                paraguai: ``,
                reinounido: ``,
                suica: ``,
            },
            27: `Read more`,
        },
        data: {
            2021: {
                percentInTreatment: {
                    argentina: `This year, Argentina only registered the start of treatment for pregnant women.`,
                    brasil: ``,
                    colombia: ``,
                    guatemala: ``,
                    paraguai: `55 were women and 45 were men.`,
                    reinounido: ``,
                    suica: ``,
                },
            },
            2022: {
                percentInTreatment: {
                    argentina: ``,
                    brasil: ``,
                    colombia: ``,
                    guatemala: `Some of the individuals who started treatment were diagnosed in previous years.`,
                    paraguai: `There is no classification by age and sex, as well as if pregnant.`,
                    reinounido: `This country does not classify among the individuals who started treatment, sex, and age.`,
                    suica: `The individual who started treatment was aged 15 to 49, not pregnant.`,
                },
            },
            2023: {
                percentInTreatment: {
                    argentina: ``,
                    brasil: `Some of the individuals who started treatment were diagnosed in previous years.`,
                    colombia: ``,
                    guatemala: ``,
                    paraguai: `70 were female and 49 were male.`,
                    reinounido: ``,
                    suica: ``,
                },
            },
        },
        tooltips: {
            1: ``,
            "1a": {
                2023: {
                    argentina: `thousand`,
                    brasil: `thousand`,
                    colombia: `thousand`,
                    guatemala: `thousand`,
                    paraguai: `thousand`,
                    reinounido: `thousand`,
                    suica: `thousand`,
                },
                2022: {
                    argentina: `thousand`,
                    brasil: `thousand`,
                    colombia: `million`,
                    guatemala: `thousand`,
                    paraguai: `thousand`,
                    reinounido: `thousand`,
                    suica: `thousand`,
                },
                2021: {
                    argentina: `thousand`,
                    brasil: `thousand`,
                    colombia: `thousand`,
                    guatemala: `thousand`,
                    paraguai: `thousand`,
                    reinounido: `thousand`,
                    suica: `thousand`,
                },
            },
            "1b": `people tested`,
            "1c": `thousand`,
            "1d": `positive diagnoses`,
            "1e": `Women`,
            "1f": `Men`,
            "1g": `Others`,
            2: `of diagnosed individuals started treatment`,
            3: ``,
            "3a": `acute`,
            "3b": `chronic`,
            4: ``,
            "4a": `Oral Transmission`,
            "4b": `Vector Transmission`,
            "4c": `Vertical Transmission`,
            "4d": `Not Classified`,
            5: ``,
            "5a": `thousand`,
            "5b": `people tested`,
            "5c": `thousand`,
            "5d": `pregnant women`,
            "5e": `Women`,
            "5f": `Men`,
            "5g": `Others`,
            6: ``,
            "6a": `thousand`,
            "6b": `pregnant women`,
            "6c": `thousand`,
            "6d": `positive diagnoses`,
            "6e": `Women`,
            "6f": `Men`,
            "6g": `Others`,
            7: {
                2023: {
                    argentina: `of diagnosed pregnant women started treatment`,
                    brasil: `diagnosed pregnant women started treatment`,
                    colombia: `of diagnosed pregnant women started treatment`,
                    guatemala: `of diagnosed pregnant women started treatment`,
                    paraguai: `of diagnosed pregnant women started treatment`,
                    reinounido: `of diagnosed pregnant women started treatment`,
                    suica: `of diagnosed pregnant women started treatment`,
                },
                2022: {
                    argentina: `of diagnosed pregnant women started treatment`,
                    brasil: `of diagnosed pregnant women started treatment`,
                    colombia: `of diagnosed pregnant women started treatment`,
                    guatemala: `of diagnosed pregnant women started treatment`,
                    paraguai: `of diagnosed pregnant women started treatment`,
                    reinounido: `of diagnosed pregnant women started treatment`,
                    suica: `of diagnosed pregnant women started treatment`,
                },
                2021: {
                    argentina: `of diagnosed pregnant women started treatment`,
                    brasil: `of diagnosed pregnant women started treatment`,
                    colombia: `of diagnosed pregnant women started treatment`,
                    guatemala: `of diagnosed pregnant women started treatment`,
                    paraguai: `of diagnosed pregnant women started treatment`,
                    reinounido: `of diagnosed pregnant women started treatment`,
                    suica: `of diagnosed pregnant women started treatment`,
                },
            },
            8: ``,
            "8a": `thousand`,
            "8b": `people tested`,
            "8c": `thousand`,
            "8d": `tested under [z] years old`,
            "8e": `Youths`,
            "8f": `Children`,
            "8g": `Others`,
            9: ``,
            "9a": `thousand`,
            "9b": `tested under [z] years old`,
            "9c": `thousand`,
            "9d": `positive diagnoses`,
            "9e": `Youths`,
            "9f": `Children`,
            "9g": `Others`,
            10: `of children diagnosed started <strong>treatment</strong>`,
            11: ``,
            12: ``,
            13: ``,
            14: ``,
            15: ``,
            16: ``,
            17: ``,
            18: ``,
            19: ``,
            20: ``,
            21: ``,
            22: ``,
            23: ``,
            24: ``,
            25: ``,
            26: ``,
        },
        general: {
            readMore: `Read more`,
        },
        footer: {
            title: `What do you think of the Observatory?`,
            helpText: `Help us promote this initiative and engage with more stakeholders! Share it!`,
            contact: `Get in touch with us`,
            text: `Carried out by`,
        },
    },
};

export default data;
