import { useEffect, useState } from "react";
import styled from "styled-components";

import TranslatorText from "../libs/translator_text";

const Holder = styled.div`
  position: relative;
  overflow: hidden;
  height: 700px;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;

  @media (max-width: 768px) {
    height: 450px;
  }
`;

const Slider = styled.div`
  position: absolute;
  left: 0px;
  top: 0pc;
  transition: 0.5s;
  height: 700px;
  z-index: 1;
  @media (max-width: 768px) {
    height: 400px;
  }
`;

const Slide = styled.div`
  float: left;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 700px;
  background-color: #000;
  z-index: 1;
  position: relative;
  width: 100%;
  @media (max-width: 768px) {
    height: 400px;
  }
`;

const Gradient = styled.div`
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
  width: 100%;
  height: 68px;
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: 2;
`;

const Label = styled.div`
  position: absolute;
  bottom: 16px;
  left: 64px;
  right: 64px;
  color: #fff;
  font-size: 24px;
  line-height: 150%;
  z-index: 3;

  @media (max-width: 768px) {
    bottom: 16px;
    left: 32px;
    right: 32px;
    font-size: 16px;
  }
`;

const Left = styled.img`
  position: absolute;
  left: 64px;
  top: 50%;
  margin-top: -50px;
  z-index: 2;
  cursor: pointer;

  @media (max-width: 768px) {
    left: 32px;
  }
`;

const Right = styled.img`
  position: absolute;
  right: 64px;
  top: 50%;
  margin-top: -50px;
  z-index: 2;
  cursor: pointer;

  @media (max-width: 768px) {
    right: 32px;
  }
`;

export default function Slideshow() {
  const [size, setSize] = useState(0);
  const [step, setStep] = useState(0);

  function handleWindowSizeChange() {
    if (window.innerWidth >= 1440) {
      setSize(1440);
    } else {
      setSize(window.innerWidth);
    }
  }

  const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const goRight = () => {
    let _step = step + 1;
    if (_step > items.length - 1) {
      _step = 0;
    }
    setStep(_step);
  };

  const goLeft = () => {
    let _step = step - 1;
    if (_step < 0) {
      _step = items.length - 1;
    }
    setStep(_step);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    window.addEventListener("load", handleWindowSizeChange);
    handleWindowSizeChange();
  }, []);

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe)
      console.log("swipe", isLeftSwipe ? goRight() : goLeft());
    // add your conditional logic here
  };

  return (
    <Holder>
      <Left src="/img/slideshow/arrow_left.svg" onClick={goLeft} />
      <Right src="/img/slideshow/arrow_right.svg" onClick={goRight} />
      <Slider
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        style={{
          width: items.length * size,
          left: step * size * -1,
        }}
      >
        {items.map((item) => {
          return (
            <Slide
              key={item}
              style={{
                width: size,
                backgroundImage: `url('/img/slideshow/image${item}.jpg')`,
              }}
              title={TranslatorText(`home.slideshowAlt.${item}`)}
            >
              <Gradient />
              <Label>{TranslatorText(`home.slideshow.${item}`)}</Label>
            </Slide>
          );
        })}
      </Slider>
    </Holder>
  );
}
