import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { useTranslation } from "react-i18next";

const Holder = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

  video {
    width: auto;
    height: 100%;
    max-width: 90%;
    max-height: 80%;
    background: transparent;
  }
`;

const Close = styled.div`
  position: fixed;
  right: 10px;
  top: 10px;
  font-weight: 700;
  color: #fff;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
`;

export default function Player({ setOpen }) {
  const { i18n } = useTranslation();
  const selectedLanguage = i18n.language.substring(0, 2);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    window.addEventListener("load", handleWindowSizeChange);
  }, []);

  return (
    <Holder
      onClick={() => {
        setOpen(false);
      }}
    >
      <Close
        onClick={() => {
          setOpen(false);
        }}
      >
        ✕
      </Close>
      <video controls autoPlay playsInline>
        <source
          src={`/video/${selectedLanguage}${width < 768 ? "-mobile" : ""}.mp4`}
          type="video/mp4"
        />
      </video>
    </Holder>
  );
}
