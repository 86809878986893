import React, { useState } from "react";
import styled from "styled-components";

import Player from "./player";

const Holder = styled.div`
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;

  @media (max-width: 768px) {
  }
`;

const Play = styled.img`
  width: 92px;
  height: 92px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -46px;
  margin-top: -46px;
  transition: 0.5s;
  cursor: pointer;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`;

export default function Video({ width, height, cover }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      {open && <Player setOpen={setOpen} />}
      <Holder
        style={{
          width: width,
          height: height,
          backgroundImage: `url(${cover})`,
        }}
      >
        <Play
          src="/img/home/play.svg"
          onClick={() => {
            setOpen(true);
          }}
        />
      </Holder>
    </>
  );
}
