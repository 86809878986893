import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import Question from "../shared/question";

const Holder = styled.div`
  width: 100%;
  height: 460px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 12px;
  /* overflow: hidden; */
  margin-bottom: 32px;
  &.last {
    margin-bottom: 0px;
  }

  @media (max-width: 768px) {
    display: block;
    height: auto;
  }
`;

const ChartHolder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: start;
  justify-content: end;
  padding: 40px;

  @media (max-width: 768px) {
    padding: 24px;
  }
`;

export default function PieCustom({
  cNumber,
  number,
  title,
  labels,
  values,
  extraTooltip,
}) {
  console.log(values);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    window.addEventListener("load", handleWindowSizeChange);
  }, []);

  Chart.register(CategoryScale);
  const state = {
    labels: labels.split(","),
    datasets: [
      {
        backgroundColor: [
          "#EF642E",
          "#6A6E53",
          "#404751",
          "#e3ccbc",
          "#FFDC5C",
        ],
        hoverBackgroundColor: [
          "#EF642E",
          "#6A6E53",
          "#404751",
          "#e3ccbc",
          "#FFDC5C",
        ],
        data: values,
      },
    ],
  };

  return values && labels && labels.length > 0 && values.length > 0 ? (
    <Holder className="pie">
      <Question
        cNumber={cNumber}
        number={number}
        title={title}
        tooltip={extraTooltip}
      >
        <ChartHolder>
          <Doughnut
            data={state}
            // width={400}
            height={width < 1024 ? 380 : 418}
            options={{
              maintainAspectRatio: false,
              responsive: true,
              cutout: 50,
              radius: 150,
              plugins: {
                tooltip: {
                  enabled: true,
                },
                legend: {
                  display: true,
                  position: width < 1024 ? "top" : "left",
                  labels: {
                    boxWidth: 10,
                    boxHeight: 10,
                    useBorderRadius: true,
                    borderRadius: 20,
                    color: "rgba(64, 71, 81, 1)",
                    font: "Moderat",
                  },
                },
              },
            }}
          />
        </ChartHolder>
      </Question>
    </Holder>
  ) : null;
}
