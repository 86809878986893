import React from "react";
import styled from "styled-components";

import { Tooltip } from "react-tooltip";

import Question from "../shared/question";

const Holder = styled.div`
  width: 100%;
  height: 460px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 32px;
  &.last {
    margin-bottom: 0px;
  }

  @media (max-width: 768px) {
    display: block;
    height: auto;
  }
`;

const Chart = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: start;
  justify-content: end;
  padding: 40px;

  @media (max-width: 768px) {
    padding: 24px;
  }
`;

const ChartHolder = styled.div`
  width: 194px;
  height: 380px;
  position: relative;
  background-color: #e0ebe3;
`;

const ChartTop = styled.div`
  position: absolute;
  right: 194px;
  margin-right: 16px;
  top: 30px;
  color: #404751;

  text-align: right;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: -1px;

  strong {
    color: #404751;
    font-size: 20px;
    font-weight: bold;
    /* font-size: 16px;
    strong {
      font-size: 16px;
    } */
  }
`;

const ChartBottom = styled.div`
  position: absolute;
  right: 194px;
  margin-right: 16px;
  bottom: 20px;
  color: #f15922;

  text-align: right;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: -1px;

  strong {
    color: #f15922;
    font-size: 20px;
    font-weight: bold;
    /* font-size: 16px; */
    strong {
      /* font-size: 16px; */
    }
  }
`;

const ChartFull = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 0px;
  height: 350px;
  width: 100%;
  background-color: #cfe0d3;
`;

const ChartProgress = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background-color: #ef642e;
`;

const ChartMask = styled.img`
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 4;
`;

const ChartLine = styled.img`
  position: absolute;
  left: 19px;
  top: 30px;
  z-index: 3;
`;

export default function Becker({
  flag,
  cNumber,
  number,
  title,
  topValue,
  topUnit,
  topLabel,
  bottomValue,
  bottomUnit,
  bottomLabel,
  manLabel,
  womanLabel,
  othersLabel,
  man,
  woman,
  others,
  tooltip,
  extraTooltip,
}) {
  return topValue && bottomValue ? (
    <Holder className="becker">
      <Question
        cNumber={cNumber}
        number={number}
        title={title}
        tooltip={extraTooltip}
      >
        <Chart>
          <ChartHolder>
            <ChartFull>
              <ChartProgress
                style={{
                  height: `${
                    (parseInt(bottomValue) / parseInt(parseInt(topValue))) * 100
                  }%`,
                }}
              />
            </ChartFull>
            <ChartLine src="/img/charts/lines_becker.svg" />
            <ChartMask
              src="/img/charts/mask_becker.svg"
              data-tooltip-id={
                (woman || man || others || tooltip) && `${flag}_${number}_data`
              }
            />
            <ChartTop>
              <strong>
                {parseInt(topValue) < 1000 && parseInt(topValue)}
                {parseInt(topValue) > 999 &&
                  parseInt(topValue) < 999999 &&
                  `${(parseInt(topValue) / 1000).toFixed(0)} ${topUnit}`}
                {parseInt(topValue) > 999999 ? `1 ${topUnit}` : ""}
              </strong>
              <br /> {topLabel}
            </ChartTop>
            <ChartBottom>
              <strong>
                {parseInt(bottomValue) < 1000
                  ? parseInt(bottomValue)
                  : (parseInt(bottomValue) / 1000).toFixed(0)}{" "}
                {parseInt(bottomValue) > 999 && bottomUnit}
              </strong>
              <br /> {bottomLabel}
            </ChartBottom>
          </ChartHolder>
        </Chart>
        <Tooltip id={`${flag}_${number}_data`} place="left" className="tooltip">
          {woman ? (
            <>
              {womanLabel}: {woman}
              <br />
            </>
          ) : null}

          {man ? (
            <>
              {manLabel}: {man}
              <br />
            </>
          ) : null}

          {others ? (
            <>
              {othersLabel}: {others}
              <br />
            </>
          ) : null}
          {tooltip ? <>{tooltip}</> : null}
        </Tooltip>
      </Question>
    </Holder>
  ) : null;
}
