// Por último, importamos tudo e exportamos um único objeto

import ptBrTranslations from "./pt-br";
import enUsTranslations from "./en-us";
import esTranslations from "./es";

// https://support.mozilla.org/pt-BR/kb/abreviacao-de-localizacao

const data = {
  "pt-BR": ptBrTranslations,
  "en-US": enUsTranslations,
  es: esTranslations,
};

export default data;
