export default function Helper() {
  const isMobile = function () {
    return window.innerWidth <= 768;
  };
  const screenWidth = function () {
    return window.innerWidth;
  };
  const screenHeight = function () {
    return window.innerHeight;
  };
  const numberWithDots = function (x) {
    if (!x) {
      return "";
    }
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
  };
  const numberRound = function (x) {
    if (!x) {
      return "";
    }
    return x < 1000 ? x : (x / 1000).toFixed(0);
  };
  return {
    isMobile,
    screenWidth,
    screenHeight,
    numberWithDots,
    numberRound,
  };
}
