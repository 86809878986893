import React from "react";
import styled from "styled-components";

import Question from "../shared/question";

import { Tooltip } from "react-tooltip";

const Holder = styled.div`
  width: 100%;
  height: 460px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 32px;
  &.last {
    margin-bottom: 0px;
  }

  @media (max-width: 768px) {
    display: block;
    height: auto;
  }
`;

const Chart = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: start;
  justify-content: end;
  padding: 40px;

  @media (max-width: 768px) {
    padding: 24px;
  }
`;

const ChartHolder = styled.div`
  width: 234px;
  height: 380px;
  position: relative;
  background-color: #e0ebe3;
  border-radius: 12px;

  @media (max-width: 768px) {
    width: 200px;
  }
`;

const ChartBottom = styled.div`
  position: absolute;
  right: 100%;
  margin-right: 16px;
  bottom: 0px;
  color: #404751;

  text-align: right;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: -1px;

  strong {
    color: #404751;
    font-size: 20px;
    font-weight: bold;
  }
`;

const AllDots = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
`;

const ImageHolder = styled.div`
  flex: 1;
  flex-basis: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 20%;
`;

const Image = styled.img`
  width: 50px;
  height: 50px;
`;

const Clear = styled.div`
  clear: both;
`;

export default function Dots({
  cNumber,
  number,
  flag,
  title,
  percent,
  label,
  tooltip,
  extraTooltip,
  hidePercentage,
  selectedYear,
}) {
  return percent ? (
    <Holder className="dots">
      <Question
        cNumber={cNumber}
        number={number}
        title={title}
        tooltip={extraTooltip}
      >
        <Chart>
          <ChartHolder data-tooltip-id={tooltip && `${number}_${flag}_dots`}>
            <AllDots>
              {[10, 9, 8, 7, 6, 5, 4, 3, 2, 1].map((index) => {
                return percent / 10 < index ? (
                  <ImageHolder key={index}>
                    <Image
                      src="/img/charts/dot_off.svg"
                      style={{
                        transform: `rotate(${Math.random() * 360}deg)`,
                      }}
                    />
                  </ImageHolder>
                ) : (
                  <ImageHolder key={index}>
                    <Image src="/img/charts/dot_on.svg" />
                  </ImageHolder>
                );
              })}
              <Clear />
            </AllDots>
            <ChartBottom>
              <strong>
                {percent}
                {!hidePercentage && "%"}
              </strong>
              <br /> {label}
            </ChartBottom>
          </ChartHolder>
        </Chart>
      </Question>
      <Tooltip id={`${number}_${flag}_dots`} place="left" className="tooltip">
        {tooltip}
      </Tooltip>
    </Holder>
  ) : null;
}
