import React from "react";
import styled from "styled-components";

import translatorText from "../../libs/translator_text";

import Country from "./shared/country";

const Holder = styled.div`
  @media (max-width: 768px) {
  }
`;

export default function Guatemala() {
  return (
    <Holder>
      <Country
        number={"04"}
        name={translatorText("countries.guatemala.name")}
        flag={"guatemala"}
        code={"GTM"}
        description={"countries.guatemala.description"}
      />
    </Holder>
  );
}
