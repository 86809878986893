import React, { useState, useEffect } from "react";
import styled from "styled-components";

import WhiteBox from "../white_box";

import Translator from "../../libs/translator";

const Holder = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Phrase = styled.div`
  color: #404751;
  font-family: "Tiempos Headline";
  font-size: 32px;
  line-height: 125%;

  strong {
    font-size: 32px;
    font-weight: bold;
    font-family: "Tiempos Headline";
  }

  @media (max-width: 768px) {
    font-size: 24px;
    padding: 32px 0px;

    strong {
      font-size: 24px;
    }
  }
`;

const Numbers = styled.div`
  margin-left: 32px;
  width: 32%;
  height: 654px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(/img/home/year_numbers.svg);
  border-radius: 12px;
  overflow: hidden;

  display: flex;
  align-items: start;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0px;
    margin-top: 32px;
  }
`;

const Year = styled.div`
  word-wrap: break-word;
  width: 100px;
  color: #ef642e;

  text-align: center;
  font-size: 200px;
  font-style: normal;
  font-weight: 900;
  line-height: 155px;
`;

export default function Years() {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const [year, setYear] = useState(1924);

  const floater = () => {
    const topPosition =
      document.getElementById("numbers").offsetTop - window.scrollY;

    let percent = (height - topPosition - 654) / (height - 654);
    if (percent < 0) {
      percent = 0;
    }
    if (percent > 1) {
      percent = 1;
    }
    setYear(1924 + percent * 100);
  };
  function handleWindowSizeChange() {
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
    floater();
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    window.addEventListener("load", handleWindowSizeChange);
    window.addEventListener("scroll", floater);
    floater();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Holder>
      <WhiteBox
        paddingLeftRight={width < 768 ? 24 : 40}
        paddingUpBottom={width < 768 ? 24 : 40}
        radius={12}
        height={width < 768 ? "auto" : 654}
        width={width < 768 ? "100%" : "67%"}
      >
        <Phrase>
          <Translator path="home.years" />
        </Phrase>
      </WhiteBox>
      <Numbers id="numbers">
        <Year>{year}</Year>
      </Numbers>
    </Holder>
  );
}
